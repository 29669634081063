/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { computed, ref, watch, shallowRef } from 'vue';
import { IapComponent } from '../../../component/domain/iapComponent';
import { LocalService, MessageService } from "../../../../../common/infrastructure/servicios";
import CatalogExpConst from "../../../catalog/domain/const/CatalogExpConst";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import { Container } from 'inversify';
import { IServiceSearch } from "../../../search/application/IServiceSearch";
import { TYPES } from "../../../../../common/domain/types";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import { IapComponentDataSource } from "../../../component/domain/iapComponentDataSource";
import FiltroBusquedaConst from "../../../../../common/domain/constantes/FiltroBusquedaConst";
import {  GroupSearch } from "../../../search/domain/search";
import ExpressionNomenclatorConst from "../../../expression/domain/const/ExpressionNomenclatorConst";
import BaseControlTypeConst from "../Constants/BaseControlTypeConst";
import AutocompleteTypeConst from "../Constants/AutocompleteTypeConst";
import CatalogModelValueConst from "../../../catalog/domain/const/CatalogModelValueConst";
import ComponentDataForm from "../../../designer/domain/ComponentDataForm";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import CatalogAttrConst from "../../../catalog/domain/const/CatalogAttrConst";
//@ts-ignore:disable-next-line
import debounce from 'lodash.debounce';
import { dataUpdate } from "../../../dataupdate/domain/dataUpdate";
import OperationDataTypeConst from "../../../../../common/domain/constantes/OperationDataTypeConst";
import { claveNew } from "../../../dataupdate/domain/clave";
import SqlTypesConst from "../../../../../common/domain/constantes/SqlTypesConst";
import DataSourceUtilComp from "../../../crud/infrastructure/functions/dataSourceUtilComp";
import DataSourceConst from "../../../crud/infrastructure/functions/dataSourceConst";
import KeysDataModelComponentDataSource from "../keysDataModelComponentDataSource";
import KeysDataModelAttribute from "../keysDataModelAttribute";
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf,maxLength,minLength,maxValue,minValue,email, alpha,alphaNum,numeric,integer,decimal,ipAddress,macAddress,url,between,sameAs } from '@vuelidate/validators';
import { IapComponentDataSourceFieldConfiguration } from "../../../component/domain/iapComponentDataSourceFieldConfiguration";
import { IapDataSourceField } from "../../../datasource/domain/iapDataSourceField";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";
import ControlTypeConst from "../Constants/ControlTypeConst";
import { MenuItemClass } from '../Controls/MenuItemClass';
import MenuItemTypeConst from "../Constants/MenuItemTypeConst";
import { useRouter } from 'vue-router'
import { IapWorkFlow } from "../../../workflow/domain/service/iapWorkFlow";
import { executeWorkFlow } from "../../../workflow/infrastructure/component/rete/rete/editor";
import { IapComponentEvent } from "../../../component/domain/iapComponentEvent";
import HelperOperationData from "../../../../../common/infrastructure/funciones/HelperOperationData";
import EventBusCustom from '../../../../../common/infrastructure/event-bus-custom';
import EventConst from "../../../../../common/domain/constantes/EventConst";
import { MessageType } from "../../../../../common/infrastructure/servicios/MessageService";
import { Dictionary } from "../../../expression/domain/dictionary";
import DataSourceComp from "../../../crud/infrastructure/functions/dataSourceComp";
import { IapExpression } from '../../../expression/domain/iapExpression';
import helperCatalog from "../../../catalog/infrastructure/helper/helperCatalog";
import { InteractionEvent } from '../../../interaction/domain/interactionEvent';
import InteractionConst from '../../../interaction/domain/interactionConst';
import { IapComponentAttribute } from '../../../component/domain/iapComponentAttribute';
import { fetchWrapper } from '../../../../../common/infrastructure/funciones/helperFetch';
import { Store } from 'vuex';
import CatalogValidationTypeConst from '../../../catalog/domain/const/CatalogValidationTypeConst';
import { DataUpdateOperation } from '../../../dataupdate/domain/dataUpdateOperation';
import HelperSecurity from '../../../../../common/infrastructure/funciones/HelperSecurity';
import ObjectGroupConst from '../../../../../common/domain/constantes/ObjectGroupConst';
import { ContactChat } from '../../../chat/domain/contactChat';
import CatalogDataSourceType from '../../../catalog/domain/const/CatalogDataSourceType';
import DropDownTypeConst from '../Constants/DropDownTypeConst';
import CrudTableTypeConst from '../Constants/CrudTableTypeConst';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import Environment from '../../../../../common/infrastructure/funciones/environment';
import HelperCompress from '../../../../../common/infrastructure/funciones/HelperCompress';
import CdsPropsConst from '../../../catalog/domain/const/CdsPropsConst';
import TabViewTypeConst from '../Constants/TabViewTypeConst';
import { ResultadSearch } from '../../../search/domain/resultSearch';
import { SearchMultiple } from '../../../search/domain/searchMultiple';
import ComponentHelperRender from './ComponentHelperRender';

export default function ComponentCommonRender(
    Component: ComponentDataForm,    
    slotProps:any,
    container: Container | undefined ,    
    store: Store<any>,
    callBackLoaded:any | undefined = undefined) {

      
  const {iapComponentAttributesRepeater,    getPropertyValueOption,
    
    resolveExpressions,
    isVisibleTabPanel,
    getProperty,getRepeaterCurrentIndex, getLayoutComponent, getContentComponent, isLayoutComponent, getAllLayoutComponent, getPropertyValue, getPropertyNumberValue, getPropertyBooleanValue
   } = ComponentHelperRender(Component,slotProps,store);

  const componentKey = shallowRef(HelperUtils.newGuid())
  
    
  const debounceTime = 1200;
  let debounceUpdateData: any;
  let submitted = ref(false);
  const user = store?.getters.getCurrentUser;
 
  //const hasVmodel = !HelperCommon.isNullOrWhitespace(getProperty(BaseControlTypeConst.MODELVALUE,Component)?.value ?? '');
  // se inicializa aqui el vmodel
  const vmodel = shallowRef();
  const router = useRouter();
  const lookUpData = shallowRef();
  const newRegisterLookUp = shallowRef();
  const fieldKey = shallowRef('');
  const lookUpFilter = shallowRef();
  const fieldKeyComponentDataSourceId = shallowRef(-1)
  const keyComponentEventBus = Component.formKey + Component.rootParentId.toString() + Component.id.toString() + EventConst.INTERACTION;
  
  const { getFieldFromKeyCol, getFieldKeyColumn } = DataSourceComp(container as any, {Component:Component}, undefined, undefined, {} as any, [],store)
  
  const loaded = shallowRef(false);


  const activeIndex =shallowRef(0); // lo usamos solo para los tabviews

  const rootParentAttr = computed({
    get: () => Component.get(Component.rootParentId,'iapComponentAttributes') as IapComponentAttribute[],
    set: (val) => Component.set(Component.rootParentId,'iapComponentAttributes', val as any),
  });


  const componentDataSources = computed({
    get: () => Component.get(-1,'iapComponentDataSources') as IapComponentDataSource[],
    set: (val) => Component.set(-1,'iapComponentDataSources', val as any),
  });

  const getDictionaryKeys = () => {
    const localData = LocalService.getValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString());
    const data = HelperUtils.jsonParse(localData,[])

    if (data && data?.length > 0) {
      return data.map((x: any) => x.key);
    }
    return [];
  }

  const dictionaryKeys = getDictionaryKeys();
  //const iapComponentAttributesRepeater : Dictionary<number, IapComponentAttribute>[] = ([]); 

  const getModelValue = () => {
    let model: any;
    const mv = getProperty(BaseControlTypeConst.MODELVALUE)

    if (mv && mv.value) {

      if (mv.idModelValueType == CatalogModelValueConst.MODELVALUE_PROPERTY) {
        // hay que sacar las propiedades del padre
        // AQUI HAY UN ERROR CUANDO ES UN DATAVIEW O DATA TIME LINE, LAS PROPIEDADES A MIRAR NO SON SOLO LAS DE SU ROOT, SINO LAS DE SU PADRE
        let _model = rootParentAttr.value?.find((x: any) => x.id == mv.value)
        if (_model == null) {
          _model = Component.iapComponentAttributes?.find(x => x.id == mv.value)
        }
        if (_model) {
          model = HelperUtils.resolveAttrValue(_model.idDataType,_model['value'])
        }
      }
      else {

        // hay que sacar el field del ds
        const ids = mv.value?.split('#');
        const dataSourceFieldId = ids[0];
        fieldKeyComponentDataSourceId.value = parseInt(ids[1]);
        // para las tablas, dataviews,...

        const data = slotProps?.data ?? slotProps?.slotProps?.data
       
        if (data) {
          HelperUtils.handleDates(data as any);
          fieldKey.value = getDsFieldKey(fieldKeyComponentDataSourceId.value, dataSourceFieldId) as any

          if (fieldKey.value) {
            model = data[fieldKey.value]
          }
        }
        else {
          
          if (Component.idType == ControlTypeConst.CTLOOKUPEDITOR) {
            model = getLookUpModel(fieldKeyComponentDataSourceId.value, dataSourceFieldId);
          }
          else {
            model = getDsFieldModel(fieldKeyComponentDataSourceId.value, dataSourceFieldId);
          }
        }
      }
    }

    return model;
  };

  const setModelValue = (value: any) => {    
    const mv = getProperty(BaseControlTypeConst.MODELVALUE)
    
    if (mv && mv.value) {
      if (mv.idModelValueType == CatalogModelValueConst.MODELVALUE_PROPERTY) {
        // validamos que sea un guid
        if (HelperUtils.isUUID(mv.value)) {
          // hay que sacar las propiedades del padre
          let _model = rootParentAttr.value?.find((x: any) => x.id == mv.value)
          if (_model == null) {
            _model = Component.iapComponentAttributes?.find(x => x.id == mv.value)
          }
          if (_model) {
            _model['value'] = value;
            updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, _model['id']].join(ExpressionNomenclatorConst.SEPARATOR), value);
          }

        }

      }
      else {
        // hay que sacar el field del ds
        const ids = mv.value?.split('#');
        const dataSourceFieldId = ids[0];
        const componentDataSourceId = parseInt(ids[1]);

        // para las tablas, dataviews,...

        const data = slotProps?.data ?? slotProps?.slotProps?.data

        if (data) {
          const fieldKey = getDsFieldKey(componentDataSourceId, dataSourceFieldId)

          if (fieldKey) {
            data[fieldKey] = value
          }
        }
        else {
          if (Component.idType == ControlTypeConst.CTLOOKUPEDITOR) {
            if(value){              
              value?.forEach((dicElement: any) => {
                setDsFieldModel(componentDataSourceId, dicElement.key, dicElement.value);
              });
            }           
          }
          else {
            setDsFieldModel(componentDataSourceId, dataSourceFieldId, value);
          }
        }
      }
    }
  };



  const getDsFieldKey = (componentDataSourceId: number, dataSourceFieldId: string) => {
    const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);
    if (dsc) {
      
      const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
      if (field) {
        if (dsc.dataSource.iapDataSourceDataBase) {
          const fieldKey = getFieldKeyColumn(field,(field.dataSourceTableAliasId == null));
          return fieldKey;
        }
        else if (dsc.dataSource.iapDataSourceService) {
          const fieldKey = [(!HelperCommon.isNullOrWhitespace(field.field ?? '') ? field.field : field.fieldAlias)].join(DataSourceConst.DELIMITERFIELDS)
          return fieldKey;
        }
      }
    }

    return null;
  }

  const getDsFieldModel = (componentDataSourceId: number, dataSourceFieldId: string) => {
    const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);
    if (dsc) {
      const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
      if (field) {
        fieldKey.value = getDsFieldKey(componentDataSourceId, dataSourceFieldId) as any
        if (fieldKey.value) {
          if (Array.isArray(dsc.dataSource.items) ) {
            const first = dsc.dataSource.items?.find((x: any) => x !== undefined)

            const model = first?.[fieldKey.value];// dsc.dataSource.items.find(x=>x!==undefined)?.map(x=> x[fieldKey])
            return model;
          }
          else {
            const separatedKeys = fieldKey.value.split('.');
            let result = dsc.dataSource.items;
            if(result){
              separatedKeys.forEach(k => {
                result = result[k];
              });
            }
            const model = result;
            return model;
          }
        }

      }
    }
    return null;
  }

  const getLookUpModel = (componentDataSourceId: number, dataSourceFieldId: string) => {

    let dic: Dictionary<String, String>[] = ([]);
    const lookUp = getDsLookUp();
    const fieldMaps = lookUp?.iapDataSourceLookUpFieldMaps?.filter(x => x.dataSourceFieldIdTarget != null && x.isFieldLinked).sort((a, b) => a.id - b.id);

    fieldMaps?.forEach(field => {

      dic.push({
        key: field.dataSourceFieldIdTarget,
        value: getDsFieldModel(componentDataSourceId, field.dataSourceFieldIdTarget)
      } as any)
    });

    //fieldkey se tiene que actualizar finalmente con el valor del lookup
    fieldKey.value = getDsFieldKey(componentDataSourceId, dataSourceFieldId) as any

    return dic;
  }

  const setDsFieldModel = (componentDataSourceId: number, dataSourceFieldId: string, value: any) => {    
    const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);
    if (dsc) {
      const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
      if (field) {
        const fieldKey = getDsFieldKey(componentDataSourceId, dataSourceFieldId)
        if (fieldKey) {
          const first = dsc.dataSource.items?.find((x: any) => x !== undefined)
          if (first) {
            first[fieldKey] = value;
          }

          if (Component.idType == ControlTypeConst.CTLOOKUPEDITOR && value && Array.isArray(value)){
                (value as any).forEach((dicElement: any) => {
                  updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSF, dsc.id, dicElement.key].join(ExpressionNomenclatorConst.SEPARATOR), dicElement.value);
                });
          }
          else{
            updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSF, dsc.id, field.id].join(ExpressionNomenclatorConst.SEPARATOR), value);
          }

          
        }
      }
    }
  }

  const getModelValueKeys = (component = Component): KeysDataModelComponentDataSource | KeysDataModelAttribute | null => {
    const mv = getProperty(BaseControlTypeConst.MODELVALUE,component)

    if (mv && mv.value) {
      if (mv.idModelValueType == CatalogModelValueConst.MODELVALUE_DATASOURCE) {
        const ids = mv.value?.split('#');
        const _dataSourceFieldId = ids[0];
        const _componentDataSourceId = parseInt(ids[1]);
        return {
          type: CatalogModelValueConst.MODELVALUE_DATASOURCE,
          dataSourceFieldId: _dataSourceFieldId,
          componentDataSourceId: _componentDataSourceId
        } as KeysDataModelComponentDataSource
      }
      else {
        return {
          type: CatalogModelValueConst.MODELVALUE_PROPERTY,
          attributeId: mv.value

        } as KeysDataModelAttribute
      }
    }
    return null
  }

  const getDsLookUpId = () => {
    let dataSourceLookUpId = 0;
    const mv = getProperty(BaseControlTypeConst.MODELVALUE);

    if (mv && mv.value) {
      const ids = mv.value?.split('#');
      const dataSourceFieldId = ids[0];
      const componentDataSourceId = parseInt(ids[1]);

      const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);

      if (dsc) {
        const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
        if (field) {
          if (field.dataSourceLookUpId) {
            dataSourceLookUpId = field.dataSourceLookUpId;
          }
        }
      }

      return dataSourceLookUpId;
    }
  }

  const getDsFieldsLookUp = () => {
    const mv = getProperty(BaseControlTypeConst.MODELVALUE);

    if (mv && mv.value) {
      const ids = mv.value?.split('#');
      const dataSourceFieldId = ids[0];
      const componentDataSourceId = parseInt(ids[1]);

      const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);

      if (dsc) {
        const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
        if (field) {         

          return field.dataSourceLookUp?.dataSource.iapDataSourceFields;
        }
      }

      return null;
    }
  }

  const getDsLookUp = () => {
    const mv = getProperty(BaseControlTypeConst.MODELVALUE);

    if (mv && mv.value) {
      const ids = mv.value?.split('#');
      const dataSourceFieldId = ids[0];
      const componentDataSourceId = parseInt(ids[1]);

      const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);

      if (dsc) {
        const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
        if (field) {

          return field.dataSourceLookUp;
        }
      }

      return null;
    }
  }

  const getDsLookUpDesc = () => {
    const mv = getProperty(BaseControlTypeConst.MODELVALUE);

    if (mv && mv.value) {
      const ids = mv.value?.split('#');
      const dataSourceFieldId = ids[0];
      const componentDataSourceId = parseInt(ids[1]);

      const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);

      if (dsc) {
        const field = dsc.dataSource.iapDataSourceFields.find(x => x.id == dataSourceFieldId)
        if (field) {
          fieldKey.value = getDsFieldKey(componentDataSourceId, dataSourceFieldId) as any
          if (fieldKey.value) {
            const first = dsc.dataSource.items?.find((x: any) => x !== undefined)

            const desc = first?.[fieldKey.value + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION];// dsc.dataSource.items.find(x=>x!==undefined)?.map(x=> x[fieldKey])
            return desc;
          }
        }
      }
      return null;

    }
  }

  const getComponentDataSourceItemsFromId = (name: string) => {
    const value = getPropertyValue(name);

    if (value) {
      const dsc = componentDataSources.value.find(x => x.id == parseInt(value));

      if (dsc && dsc?.dataSource) {
        return dsc?.dataSource.items;
      }
    }

    return null;
  }

  const getComponentDataSource = (nameProperty: string) => {
    const value = getPropertyValue(nameProperty);

    if (value) {
      const dsc = componentDataSources.value.find(x => x.id == parseInt(value));

      if (dsc) {
        return dsc;
      }
    }

    return null;
  }




  const getOptionLabelAutocomplete = (): string => {
    const ol = getPropertyValue(AutocompleteTypeConst.OPTIONLABEL);

    if (ol) {
      const ids = ol.split('#');
      const dataSourceFieldId = ids[0];
      const componentDataSourceId = parseInt(ids[1]);

      const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);

      if (dsc && dsc.dataSource && dsc.dataSource.iapDataSourceFields) {

        const dsf = dsc.dataSource.iapDataSourceFields.find(dsf => dsf.id == dataSourceFieldId);
        let key = '';

        if (dsf) {
          if (dsf.dataSourceTableAliasId == null) {
            key = getFieldKeyColumn(dsf,true)
          }
          else {
            key = getFieldKeyColumn(dsf)
          }
        }

        if (key) {
          return key;
        }
      }
    }

    return '';
  }

  const getComponentDataSourceFromModelValue = () => {
    const mv = getProperty(BaseControlTypeConst.MODELVALUE);

    if (mv && mv.value) {
      const ids = mv.value?.split('#');
      const componentDataSourceId = parseInt(ids[1]);

      const dsc = componentDataSources.value.find(x => x.id == componentDataSourceId);

      return dsc;
    }

    return null;
  }

  const isAutoSaveDatasourceComponent = (): boolean => {
    if (modelValueKeys && modelValueKeys?.type == CatalogModelValueConst.MODELVALUE_DATASOURCE) {
      const _data = modelValueKeys as KeysDataModelComponentDataSource
      const cds = componentDataSources.value.find(x => x.id == _data.componentDataSourceId);
      return (cds && cds?.autoSave) ?? false;
    }

    return false;
  }




  const getPropertyId = (propertyName: string, component = Component) => {
    return component.iapComponentAttributes.find((x) => x.name == propertyName)
      ?.id;
  };



  

  const setPropertyValue = (propertyId: string, value: any) => {
    let attr = Component.iapComponentAttributes.find((x: IapComponentAttribute) => x.id == propertyId);

    if (attr == undefined) {
      attr = rootParentAttr.value?.find((x: IapComponentAttribute) => x.id == propertyId);
    }
    if (attr) {
      attr.value = value;
      updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, attr.id].join(ExpressionNomenclatorConst.SEPARATOR), attr.value);
    }
  };

  const setPropertyValueByName = (propertyName: string, value: any) => {
    let attr = Component.iapComponentAttributes.find((x) => x.name == propertyName);
    /*
    if (attr == undefined){
      attr = props.rootParentAttr?.find((x:IapComponentAttribute) => x.name == propertyName);
    }
    */

    if (attr) {
      attr.value = value;
      updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, attr.id].join(ExpressionNomenclatorConst.SEPARATOR), attr.value);
    }

  };

  const catalogTypes = () => {
    return helperCatalog.getAllCatalogApp();
  }

  const getCatalogValue = (propertyName: string) => {
    const prop = Component.iapComponentAttributes.find((x) => x.name == propertyName);

    const catalogType = prop?.valueListId;
    const propValue = prop?.value;

    return catalogTypes().find(x => x.id == catalogType)?.iapCatalogs?.find(x => x.id?.toLowerCase() == propValue?.toLowerCase())?.value?.toLowerCase();
  };


  const getCatalogs = (catalogTypeId: string) => {
    return catalogTypes().find((x: any) => x.id == catalogTypeId)?.iapCatalogs;
  };

  
  const updateDictionary = (key: string, value: any, dictionary:any = null) => {
    if (dictionary != null){
      const reg = dictionary.find((x: any) => x.key == key);
  
      if (reg) {
  
        reg.value = value;
      }
    }
    else{
      const localData = LocalService.getValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData,[])
  
      const reg = data.find((x: any) => x.key == key);
  
      if (reg) {
  
        reg.value = value;
        LocalService.setValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString(), JSON.stringify(data))
      }
    }
  }

  const updateDictionaryFromRow = (compDataSourceId:number,data: any, dictionary:any = null) => {
    let ds = Component.iapComponentDataSources.find(x => x.id == compDataSourceId)
    if (ds == undefined){
      ds = componentDataSources.value.find( (x:any) => x.id == compDataSourceId)
    }

    ds?.dataSource?.iapDataSourceFields?.filter(dsField => dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSF, ds?.id, dsField.id].join('.'))).forEach(dsField => {
        let _field = '';
        if (dsField.dataSourceTableAliasId == null) {
            _field = getFieldKeyColumn(dsField,true);
        }
        else {
            _field = getFieldKeyColumn(dsField);
        }
        const _value = data != null ? data[_field] : null;
        updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSF, ds?.id, dsField.id].join('.'), _value,dictionary);
    })

    // se añaden los lk data
    ds?.dataSource?.iapDataSourceFields?.filter(dsField => dsField.dataSourceLookUpId != null).map(x => ({ dataSourceFieldId: x.id, dataSourceLookUpId: x.dataSourceLookUpId, iapDataSourceFields: x.dataSourceLookUp?.dataSource.iapDataSourceFields }))
        //.filter(x =>  dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, ds.id, dsField.id].join('.')))
        .forEach(x => {
            x.iapDataSourceFields?.filter(dsField => dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsField.id].join('.')))
                .forEach((dsField: IapDataSourceField) => {

                    //@ts-ignore:disable-next-line  
                    let _field = x.dataSourceFieldId.replaceAll('-', '') + DataSourceConst.DELIMITERFIELDS + dsField.id.replaceAll('-', '');

                    const _value = data != null ? data[_field] : null;
                    updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsField.id].join('.'), _value,dictionary);

                })
        })

    if (ds?.dataSource) {
        ds.dataSource.items = data != null ? [data] : null;
    }
}

  const updateCdsDependencies = (ds: IapComponentDataSource, obj: any, index = 0) => {
    
    // se actauliza el ds si se usa en el diccionario    
    const keyDs = [ExpressionNomenclatorConst.EXPNOM_DATASOURCE, ds.id, ds.dataSourceId, ds.componentId].join(ExpressionNomenclatorConst.SEPARATOR);
    if (dictionaryKeys.includes(keyDs)) {
      updateDictionary(keyDs, obj)
    }

    
    // falta actualizar los fields que se usen
    if (ds.dataSource?.items?.length > 0) {
      const item = ds.dataSource?.items[index];
      

      ds.dataSource.iapDataSourceFields
        .filter(f => dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSF, ds.id, f.id].join(ExpressionNomenclatorConst.SEPARATOR)))
        .forEach(f => {
          
          let key = '';

          if (ds.dataSource.idDataSourceType == CatalogDataSourceType.SERVICE) // para los servicios
          {
            if (f.dataSourceTableAliasId == null) 
            {
              key = f.field
            }
            else {
              key = [ds.dataSource?.iapDataSourceDataBase?.idBaseDatos, (!HelperCommon.isNullOrWhitespace(f.tableNameAlias ?? '') ? f.tableNameAlias : f.tableName), (!HelperCommon.isNullOrWhitespace(f.fieldAlias ?? '') ? f.fieldAlias : f.field)].join(DataSourceConst.DELIMITERFIELDS);
            }

          }
          else // para las BBDD
          {
            if (f.dataSourceTableAliasId == null) {
              key = getFieldKeyColumn(f,true)
            }
            else {
              key = getFieldKeyColumn(f);
            }
          }

       
          if (key) {
            updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSF, ds.id, f.id].join(ExpressionNomenclatorConst.SEPARATOR), item[key]);
          }

        })

      // se añaden los lookups ids
      ds.dataSource?.iapDataSourceFields?.filter(dsField => dsField.dataSourceLookUpId != null).map(x => ({ dataSourceFieldId: x.id, dataSourceLookUpId: x.dataSourceLookUpId, iapDataSourceFields: x.dataSourceLookUp?.dataSource.iapDataSourceFields }))
        //.filter(x =>  dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, ds.id, dsField.id].join('.')))
        .forEach(x => {
          x.iapDataSourceFields?.filter(dsField => dictionaryKeys.includes([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsField.id].join('.')))
            .forEach(dsField => {

              //@ts-ignore:disable-next-line
              let _field = x.dataSourceFieldId.replaceAll('-', '') + DataSourceConst.DELIMITERFIELDS + dsField.id.replaceAll('-', '');

              const _value = item != null ? item[_field] : null;
              updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSLK, x.dataSourceLookUpId, dsField.id].join('.'), _value);

            })
        })
    }
  }

  

const setDataSourceValue = (ds: IapComponentDataSource,obj:any) =>{
  if (!Array.isArray(obj)) {
    ds.dataSource.items = [obj];    
  }
  else{
    ds.dataSource.items = obj;
  }
}


const buildFillDataSource = (ds: IapComponentDataSource) : SearchMultiple | null =>{

  const { getDefaultValueServiceCfg, getDefaultvalue,getFilterValueData,adjustCdsAndClauses,isStrictFilter } = DataSourceComp(container as any, {Component:Component}, undefined, {}, {} as any, [],store)
  const claves = ds.dataSource.iapDataSourceFields.filter(x => (x.filterValue?x.filterValue:getDefaultvalue(x,ds?.iapComponentDataSourceFieldConfigurations?.find(cfg=>cfg.dataSourceFieldId==x.id)?.defaultValue,null,true)) || isStrictFilter(x,ds)).map(x =>
    ({

      //@ts-ignore:disable-next-line
      id:HelperUtils.newGuid().toString().replaceAll('-',''),
      fieldId: x.id,
      filter: getFilterValueData(x.filterValue,0)??FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL, 
      value: x.filterValue? getFilterValueData(x.filterValue,1):getDefaultvalue(x,ds?.iapComponentDataSourceFieldConfigurations?.find(cfg=>cfg.dataSourceFieldId==x.id)?.defaultValue,null,true),
      valueList: (x.catalogTypeId ? [x.filterValue] : null),
      valueBool: null,
      valueNumber: null,
      valueDateTime: null,
      rangeValue: null,
      rangeNumber: null,
      rangeDateTime: null,
      required: isStrictFilter(x,ds)

    }));
    
    
    
    const dsFiltersExp=ds.dataSource.iapDataSourceFields.filter(f => hasExpressions(CatalogObjectTypeConst.DSF, [f.id, ds.id].join('#'), CatalogExpConst.EXP_FILTER));
    
    if(dsFiltersExp.length>0 && claves.length==0){
        ds.dataSource.items = []  
        
        return null;
    }

    const parameters = ds.iapComponentDataSourceServiceConfigurations?.map(x =>
      ({
        key: x.key,
        value: getDefaultValueServiceCfg(x, Component.expressions)
      }));

  
      const _claves = JSON.parse(JSON.stringify(claves))
      
              

      let  _parameters = []
      if (parameters){
        _parameters = JSON.parse(JSON.stringify(parameters))
      }

      
      const cdsCopy=adjustCdsAndClauses(ds);
      

      const searchData:GroupSearch={
        operatorLogic:OperatorLogicConst.AND,
        fields:_claves,
        children:[]
    }

    return {
      applicationId:Component.applicationId,
      applicationversion:Component.applicationVersion,
      componentId:Component.id,
      requestData: searchData as any,
      parameters: _parameters,
      maxregs : ds.maxRowsReturned,
      componentDataSource:cdsCopy ,
      dataSourceId: ds.dataSourceId,
      count:undefined,
      lazyParams:undefined
    } as SearchMultiple


}

  const fillDataSource = async (ds: IapComponentDataSource): Promise<boolean> => {
    return await fillDataSourceMultiple([ds])
  }


  const fillDataSourceMultiple = async (dsList: IapComponentDataSource[]): Promise<boolean> => {
    return await new Promise<boolean>((resolve) => {
      const inputsDs = new Array<SearchMultiple>()
      //const { getDefaultValueServiceCfg, getDefaultvalue,getFilterValueData,adjustCdsAndClauses,isStrictFilter } = DataSourceComp(container as any, {Component:Component}, undefined, {}, {} as any, [],store)
      let mustExit = false
      dsList?.some( (ds:IapComponentDataSource) =>{
        const _auxData =buildFillDataSource(ds)
        if (_auxData){
          inputsDs.push(_auxData)
        }
        else{
          mustExit = true;
          return true

        }
        

      })

      if(mustExit){
        resolve(false)
        return
      }
      

      if (container && inputsDs.length > 0) {

     
        const _srv = container.get<IServiceSearch>(
          TYPES.SEARCH_REPOSITORY
        );
        HelperLoading.showLoading();
        _srv.searchMultiple(inputsDs)
          .then((responses:ResultadSearch[]) => {
            responses.forEach((response:ResultadSearch, index:number) =>{
              const ds = dsList[index]
              let obj = JSON.parse(response?.items ?? '[]');
            
              if (ds.dataSource.idDataSourceType == CatalogDataSourceType.DATABASE) {
                
                HelperUtils.handleDates(obj as any)
                //ds.dataSource.items = obj
                ds.dataSource.items = []
                Object.assign(ds.dataSource.items, obj);
                updateCdsDependencies(ds, obj)
              }
              else 
              {
                // al servicio siempre hay que hacerle un parse otra vez
                
                obj = JSON.parse(obj);
                              
                // sacamos las clases               
                const tables = ds.dataSource?.iapDataSourceTableAliases?.filter(x=> !HelperCommon.isNullOrWhitespace(x.tableName) && !x.isInput).map(x=> x.tableName).filter((value, index, array) => array.indexOf(value) === index) ?? []
                
                if(tables.length > 0)
                {
                  tables.forEach((tbl:string) =>{
                    try{
                      const  _obj = HelperUtils.propValue(obj,tbl)
                      setDataSourceValue(ds,_obj)                    
                    }catch
                    {
                      // no se encontro prop value                    
                      MessageService.showMessage(MessageType.Error, 'Error al resolver los datos del servicio', 'No se ha encontrado el objeto' + tbl);
                      setDataSourceValue(ds,obj)
                    }
                    
                  })
                   
                }
                else
                {
                  setDataSourceValue(ds,obj)
                }
  
                updateCdsDependencies(ds, obj)
  
              }

            })

           
          
          })
          .catch((err) => {
            MessageService.showMessage(MessageType.Error, 'Error al resolver el método', err);
          })
          .finally(() => {
            HelperLoading.hideLoading();
            resolve(true);
          });
      }
      else{
        resolve(false);
      }
    });
  }

  const resolveAllExpressions = (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      
      if (Component.iapComponentAttributes.find(x => x.idAttributeType == CatalogAttrConst.TIPOATTR_PROPCUS)) {
        resolveAttributes().then(() => {
          resolveDatasource().then(() => {
            resolve(true);
          });
        });
      }
      else {
        resolveDatasource().then(() => {
          resolveAttributes().then(() => {
            resolve(true);
          });
        });
      }
    })
  }


  const resolveDatasource = async (compDataSourceIdInput: number | null = null) => {
    return new Promise<boolean>(async (resolve) => {
      var promises = new Array();
      const { getFilterValueData } = DataSourceComp(container as any, {Component:Component}, undefined, {}, {} as any, [],store)
      
      if (canDoOperation(ObjectGroupConst.VIEW) &&         
        (Component.iapComponentDataSources?.length ?? 0) > 0) {
        Component.iapComponentDataSources.filter(x => x.id == (compDataSourceIdInput ?? x.id)).sort(x => x.order).forEach((ds) => {
          ds.dataSource.iapDataSourceFields.filter(f => hasExpressions(CatalogObjectTypeConst.DSF, [f.id, ds.id].join('#'), CatalogExpConst.EXP_FILTER)).forEach(f => {
            
            
            f.filterValue = resolveExpressions(CatalogObjectTypeConst.DSF, [f.id, ds.id].join('#'), CatalogExpConst.EXP_FILTER);

            if(f.filterValue && Array.isArray(getFilterValueData(f.filterValue,1))){
              const _value=getFilterValueData(f.filterValue,1);
              f.filterValue=_value[0].value.toString();
            }
          })


          ds.autoLoad = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.AUTOLOAD.key].join('#'), CatalogExpConst.EXP_SET,null,false) ?? ds.autoLoad;
          ds.autoSave = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.AUTOSAVE.key].join('#'), CatalogExpConst.EXP_SET,null,false) ?? ds.autoSave;
          ds.maxRowsReturned = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.MAXROWS.key].join('#'), CatalogExpConst.EXP_SET,null,false) ?? ds.maxRowsReturned;
          ds.order = resolveExpressions(CatalogObjectTypeConst.CDS, [ds.id, CdsPropsConst.ORDER.key].join('#'), CatalogExpConst.EXP_SET,null,false) ?? ds.order;
        

          // GILBERTO: Aqui se resuelven los valores de los parametros
          ds.dataSource.iapDataSourceServiceConfigurations?.filter(f => hasExpressions(CatalogObjectTypeConst.DSSC, [f.id, ds.id].join('#'), CatalogExpConst.EXP_DEFAULTVALUES)).forEach(f => {
            f.value = resolveExpressions(CatalogObjectTypeConst.DSSC, [f.id, ds.id].join('#'), CatalogExpConst.EXP_DEFAULTVALUES);
          })
          

        // si no tiene autocarga pero la cfg tiene algun add creamos la fila vacía
        if (!ds.autoLoad && ds.iapComponentDataSourceFieldConfigurations.find(x=> x.add === true) && Component?.idType !==ControlTypeConst.CTCRUD){
          const { insertEmptyRowToDataSource } = DataSourceComp(container as any, {Component:Component} as any, {}, {}, ds, catalogTypes().flatMap(x => x.iapCatalogs), store)
          insertEmptyRowToDataSource([],[ds]);
        }

          const isLazy=Component?.idType==ControlTypeConst.CTCRUD?getPropertyBooleanValue(CrudTableTypeConst.LAZY,Component,true):false;
          // SE LLAMA CON LOS FILTROS DE LOS DATASOURCES 
          if (((ds.autoLoad && !isLazy) || compDataSourceIdInput != null) && HelperSecurity.canDoOperation(user,(ds.dataSource?.objectGroups ?? []),ObjectGroupConst.VIEW)) {
            promises.push(ds)
            
            //promises.push(fillDataSource(ds))
          }          
        })
        
        if (promises.length > 0){
          const resuFillDs = await fillDataSourceMultiple(promises)
          resolve(resuFillDs)
         /* for (let index = 0; index < promises.length; index++) {
            
            const ds = promises[index];
            await fillDataSource(ds);                   
          }
          
          resolve(true)
          */
        }
        else{
          resolve(true);
        }
      }
      else {

        resolve(true);
      }
    });
  }

  const resolveAttributes = (attributeIdInput: string | null = null) => {
    return new Promise<boolean>((resolve) => {

    // para los dataview o controles de datos repetitivos
  
    const data = slotProps?.data ?? slotProps?.slotProps?.data
    const index = slotProps?.slotProps?.index ?? -1
    if (data &&  index >= 0){
      
      componentDataSources.value.filter(x=> x.dataSource.items).forEach(ds =>{
        updateCdsDependencies(ds, ds.dataSource.items,index)
      })
      
    }
    
    

      Component.iapComponentAttributes.filter(f => f.id == (attributeIdInput ?? f.id) &&  hasExpressions(CatalogObjectTypeConst.ATTRCOMP, f.id, CatalogExpConst.EXP_SET)).forEach(att => {
        // estoy en un dataview
    
        att.value = resolveExpressions(CatalogObjectTypeConst.ATTRCOMP, att.id, CatalogExpConst.EXP_SET);        
        
        if (data &&  index >= 0)
        {
          
          iapComponentAttributesRepeater.push(
            {
              key:index,
              value:JSON.parse(JSON.stringify(att))
            }
            )
        }
        
        
        updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, att.id].join(ExpressionNomenclatorConst.SEPARATOR), HelperUtils.resolveAttrValue(att.idDataType,att.value));
      })
      resolve(true);
    });
  }

  const hasExpressions = (idObjeto = CatalogObjectTypeConst.ATTRCOMP, objetoId: string, idTypeExpression = CatalogExpConst.EXP_SET) => {
    const exps = Component.expressions?.filter(x => x.idObjeto == idObjeto && x.idTypeExpression == idTypeExpression && (objetoId == null || x.objetoId == objetoId) && x.iapExpressionDetails?.length > 0);
    return (exps?.length > 0)
  }

  
  let modelValueKeys = getModelValueKeys();
  const canAutoSaveDatasourceComponent = isAutoSaveDatasourceComponent();

  const getComponentDataSourceFieldConfiguration = (): IapComponentDataSourceFieldConfiguration | null | undefined => {
    if (modelValueKeys && modelValueKeys?.type == CatalogModelValueConst.MODELVALUE_DATASOURCE) {
      const _dataK = modelValueKeys as KeysDataModelComponentDataSource
      const dsc = componentDataSources.value.find(x => x.id == _dataK.componentDataSourceId);
      if (dsc) {
        return dsc.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == _dataK.dataSourceFieldId);
      }
    }

    return null;
  }

  const getDataSourceFieldConfiguration = (): IapDataSourceField | null | undefined => {
    if (modelValueKeys && modelValueKeys?.type == CatalogModelValueConst.MODELVALUE_DATASOURCE) {
      const _dataK = modelValueKeys as KeysDataModelComponentDataSource
      const dsc = componentDataSources.value.find(x => x.id == _dataK.componentDataSourceId);
      if (dsc) {
        return dsc.dataSource.iapDataSourceFields.find(x => x.id == _dataK.dataSourceFieldId);
      }
    }

    return null;
  }

  const canDoOperation = (action:string, component = Component) => {
    return HelperSecurity.canDoOperation(user,(component?.objectGroups ?? []),action)
}

 const isLookUpValueEmpty = (data:any) =>{
    
      if(!data){
        return true;
      }
      if(Array.isArray(data))
      {
        const nullObj = data.flatMap(x =>x).map(x=> x['value']).find(x=> x != undefined || !x == null)
        if (HelperCommon.isNullOrNotDefined(nullObj)){
          return true;
        }
        else{
          return false;
        }
     }  

  return false;

 }

  //validaciones de las cosas
  const componentDataSourceFieldConfiguration = getComponentDataSourceFieldConfiguration();
  const dataSourceFieldConfiguration = getDataSourceFieldConfiguration();
  const canEdit = canDoOperation(ObjectGroupConst.EDIT);// (componentDataSourceFieldConfiguration?.edit ?? false);
  const valConfig=componentDataSourceFieldConfiguration?.defaultValidation;
  const validationsConfig= !HelperCommon.isNullOrWhitespace(valConfig??'')?JSON.parse(valConfig??'') as any[]:[];
  const validations=validationsConfig.flatMap(x=>x.catalogIdValidation);
  const customValMessag= ref('');
  const mandatoryControl =ControlTypeConst.VALIDABLE_CONTROLS.includes(Component?.idType)?true:false;
  const fieldNullable = dataSourceFieldConfiguration?.nullable ?? true;
  const mandatory = componentDataSourceFieldConfiguration?.mandatory ?? false;
  const required= (Component.iapComponentAttributes?.find(x=> x.name == BaseControlTypeConst.REQUIRED)?.value ?? 'false') == 'true';
    
  const rules = computed(() => ({
    vmodel: {
      required: helpers.withMessage('El campo es obligatorio', requiredIf((...args) => {
        if (!mandatoryControl || Component.idType == ControlTypeConst.CTLOOKUPEDITOR){
          return false;
        }
        else
        {
          var data = args[args.length - 2] as any;
          const isValidData = !(data == null || data == undefined || data == '')        
          return ((fieldNullable == false) || mandatory || required) && (!isValidData);
        }
      })),
      maxLengthValue:mandatoryControl && validations.includes(CatalogValidationTypeConst.MAXL)? helpers.withMessage("El campo debe tener como máximo "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MAXL)?.value1)+" caracteres", maxLength(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MAXL)?.value1)):false,
      minLengthValue:mandatoryControl && validations.includes(CatalogValidationTypeConst.MINL)? helpers.withMessage("El campo debe tener como mínimo "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MINL)?.value1)+" caracteres", minLength(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MINL)?.value1)):false,
      maxValueValue:mandatoryControl && validations.includes(CatalogValidationTypeConst.MAXV)? helpers.withMessage("El campo debe tener un valor máximo de "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MAXV)?.value1), maxValue(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MAXV)?.value1)):false,
      minValueValue:mandatoryControl && validations.includes(CatalogValidationTypeConst.MINV)? helpers.withMessage("El campo debe tener un valor mínimo de "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MINV)?.value1), minValue(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MINV)?.value1)):false,
      email:mandatoryControl && validations.includes(CatalogValidationTypeConst.EM)? helpers.withMessage("El email introducido no es correcto", email):false,
      url:mandatoryControl && validations.includes(CatalogValidationTypeConst.URL)? helpers.withMessage("La url introducida no es correcta", url):false,
      alpha:mandatoryControl && validations.includes(CatalogValidationTypeConst.ALPH)? helpers.withMessage("El campo solo acepta caracteres del alfabeto", alpha):false,
      alphaNum:mandatoryControl && validations.includes(CatalogValidationTypeConst.ALPHNUM)? helpers.withMessage("El campo solo acepta alfanuméricos", alphaNum):false,
      numeric:mandatoryControl && validations.includes(CatalogValidationTypeConst.NUM)? helpers.withMessage("El campo solo acepta valores numéricos", numeric):false,
      integer:mandatoryControl && validations.includes(CatalogValidationTypeConst.INTEG)? helpers.withMessage("El campo solo admite enteros", integer):false,
      decimal:mandatoryControl && validations.includes(CatalogValidationTypeConst.DEC)? helpers.withMessage("El campo solo admite valores decimales", decimal):false,
      ipAddress:mandatoryControl && validations.includes(CatalogValidationTypeConst.IPADDR)? helpers.withMessage("El campo solo admite formato de direccion IP", ipAddress):false,
      macAddress:mandatoryControl && validations.includes(CatalogValidationTypeConst.MACADDR)? helpers.withMessage("La dirección MAC debe tener el siguiente formato "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MACADDR)?.value1), macAddress(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.MACADDR)?.value1)):false,
      betweenValue:mandatoryControl && validations.includes(CatalogValidationTypeConst.BEETW)? helpers.withMessage("El valor debe estar entre "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.BEETW)?.value1+" y "+validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.BEETW)?.value2), between(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.BEETW)?.value1,validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.BEETW)?.value2)):false,
      sameAsRawValue:mandatoryControl && validations.includes(CatalogValidationTypeConst.SAMEAS)? helpers.withMessage("El campo debe ser igual a "+(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.SAMEAS)?.value1), sameAs(validationsConfig.find(x=>x.catalogIdValidation==CatalogValidationTypeConst.SAMEAS)?.value1)):false,
      iban:mandatoryControl && validations.includes(CatalogValidationTypeConst.IBAN)? helpers.withMessage("El iban no es correcto", (value, helpers) => {
        
       if (value && !HelperCommon.isNullOrWhitespace(value as any??'') && !HelperCommon.validarIban(value)) {
           return helpers.error('El iban no es correcto');
       }
       return !value || HelperCommon.isNullOrWhitespace(value as any??'')?"iban":value;
     }):false,
     customValidation: helpers.withMessage(customValMessag.value, (value) => {
        // los lookup editor y los controles no validables no deberian tener validaciones propias
        if (Component.idType == ControlTypeConst.CTLOOKUPEDITOR || !mandatoryControl){
          return true;
        }

          const mv = getProperty(BaseControlTypeConst.MODELVALUE);
          const ids = mv?.value?.split('#');
          if(ids && ids?.length>0){
            const dataSourceFieldId = ids[0];
            const componenDataSourcetId = parseInt(ids[1]);
            updateDictionary([ExpressionNomenclatorConst.EXPNOM_DSF, componenDataSourcetId, dataSourceFieldId].join(ExpressionNomenclatorConst.SEPARATOR), value);
          }
          
          const attrId=getPropertyId(BaseControlTypeConst.MODELVALUE);
          const result = resolveExpressions(CatalogObjectTypeConst.ATTRCOMP, attrId, CatalogExpConst.EXP_VAL);
          if(value && !HelperCommon.isNullOrWhitespace(value as any??'') && result && !HelperCommon.isNullOrWhitespace(result??'')){
            customValMessag.value=result;
            return false;
          }
          return true;
      }),
      requiredLookUp: helpers.withMessage('El campo es obligatorio', (value) => {
        
          if(Component.idType != ControlTypeConst.CTLOOKUPEDITOR){
            return true;
          } 
          const isLkEmpty = isLookUpValueEmpty(value);
          const isValidData = !(isLkEmpty)        
          return  ((fieldNullable == false) || mandatory || required) && (!isValidData)?false:true;
      }),    
      
    }
  }));

  const v$ = useVuelidate(rules, { vmodel })

  const isValidData = (): boolean => {    
    submitted.value = true;
    v$.value.$reset();
    v$.value.$touch();
    if (v$.value.$invalid) {
      HelperCommon.setFocusOnFirstError();
      return false;
    }

    return true;
  }

  const updateData = (_newValue: any, start: boolean = false,lkFieldId:string="", modelvalueKeysInput:KeysDataModelComponentDataSource | KeysDataModelAttribute | null = null) => {
    const _modelValueKeys = modelvalueKeysInput ?? JSON.parse(JSON.stringify(modelValueKeys))
   
    if (start && loaded) {
      if (_modelValueKeys && _modelValueKeys?.type == CatalogModelValueConst.MODELVALUE_DATASOURCE && canAutoSaveDatasourceComponent) {
        const _dataK = _modelValueKeys as KeysDataModelComponentDataSource;
        if(!HelperCommon.isNullOrWhitespace(lkFieldId)){
          _dataK.dataSourceFieldId=lkFieldId;
        }
        const cds = componentDataSources.value.find(x => x.id == _dataK.componentDataSourceId);
        if (cds) {
          const { getPrimaryKeys,getFieldId } = DataSourceUtilComp(cds, store)
          const _data = cds.dataSource.items.find((y: any) => y !== undefined);
          const dsf = cds.dataSource.iapDataSourceFields.find(x => x.id == _dataK.dataSourceFieldId);//  _field == (dataBaseId.value + DELIMITERFIELDS + x.tableName + DELIMITERFIELDS + x.field))?.tableName          
          const tableName = dsf?.tableName ?? '';
          const _field =getFieldKeyColumn(dsf);
          const mustSerialize = (dsf?.sqlType == SqlTypesConst.BIT) ?? false;
          const mustToString = (dsf?.sqlType == SqlTypesConst.MONEY) ?? false;
          const dataUpdate: dataUpdate = {
            valores: [{
              fieldId: getFieldId(_field, cds.dataSource.iapDataSourceFields) as any,
              value: mustSerialize ? JSON.stringify(_newValue) as any :mustToString? _newValue.toString():_newValue as any,//JSON.stringify(newValue) as any
            }],
            claves: getPrimaryKeys(cds.dataSource, _data as any, tableName as any),
            tipoOperacion: OperationDataTypeConst.UPDATE,
            id: HelperUtils.newGuid()
          };

          // se añaden los autoupdate de la configuración del componentdatasource
          const { tablasData, getDefaultvalue } = DataSourceComp(container as any, {Component:Component}, {}, {}, cds as any, catalogTypes().flatMap(x => x.iapCatalogs), store)
          cds.iapComponentDataSourceFieldConfigurations.filter(x => x.autoUpdate == true)
            .forEach(cfg => {
              const _fieldToUpdate = tablasData.value.find(x => x.id == cfg.dataSourceFieldId && x.tableName == tableName);
              if (_fieldToUpdate) {
                const newField =getFieldKeyColumn(_fieldToUpdate);                
                const newFieldValue = getDefaultvalue(_fieldToUpdate, cfg.defaultValue)
                //data[field] = newValue

                if (
                  (_fieldToUpdate?.nullable == true) // columnas que admiten nulos
                  || (_fieldToUpdate?.nullable == false && !(newFieldValue == null || newFieldValue == undefined))) // columnas no nulas que llegan valores nulos
                {
                  const mustSerialize = (_fieldToUpdate?.sqlType == SqlTypesConst.BIT) ?? false;
                  dataUpdate.valores.push
                    (
                      {
                        fieldId: getFieldId(newField,tablasData.value)as any,
                        value: mustSerialize ? JSON.stringify(newFieldValue) as any : newFieldValue as any,//JSON.stringify(newValue) as any
                      })
                }
              }
            })
          
          const doOperationTransaction = dataUpdate.valores.length > 1;
          const dataInputRequest:DataUpdateOperation={
            componentId:cds.componentId,
            componentDataSourceId:cds.id,
            dataSourceId:cds.dataSourceId,
            parameters:cds.dataSource.iapDataSourceServiceConfigurations??[],
            data:[dataUpdate]
        }
           HelperOperationData.doOperationData(container,cds.applicationId,cds.applicationVersion, [dataInputRequest], doOperationTransaction);
        }
      }
    }
  }

  const doOperationDataMassive = async (multCds: IapComponentDataSource[], exps: IapExpression[],massiveOperationType :number = OperationDataTypeConst.INSERT,transactionOperation:boolean = false): Promise<boolean> => {
    return await new Promise<boolean>((resolve) => {      
      let dataUpdateOperations:DataUpdateOperation[]=[];
      const applicationId:number=multCds[0].applicationId;
      const applicationVersion:number=multCds[0].applicationVersion;
      let tableName='';
      multCds.forEach(cds=>{
        
        let dataInput: claveNew[] = ([]);

        const {
          getPrimaryKeys,
          isColumnType
           } = DataSourceUtilComp(cds, store)
  
        const tablasData = cds.dataSource.iapDataSourceFields;
        tableName = tablasData.find(x => x.dataSourceTableAliasId !== null)?.tableName ?? '';
        const itemDataRow = cds.dataSource.items?.find((x: any) => x !== undefined)
        if (itemDataRow == undefined || itemDataRow == null) {
          MessageService.showMessage(MessageType.Error, 'Error de inserción de datos', 'No hay datos que insertar.');
          resolve(false);
        }
        else {
          // se ponen los valores por defecto usando el autoUpdate
  
          const { getDefaultvalue,getFieldId } = DataSourceComp(container as any, {Component:Component} as any, {}, {}, cds as any, catalogTypes().flatMap(x => x.iapCatalogs), store)
          cds.iapComponentDataSourceFieldConfigurations.filter(x => x.autoUpdate == true)
            .every(cfg => {
              const _fieldToUpdate = tablasData.find(x => x.id == cfg.dataSourceFieldId);
              if (_fieldToUpdate) {
                const newField = getFieldKeyColumn(_fieldToUpdate);
                const newFieldValue = getDefaultvalue(_fieldToUpdate, cfg.defaultValue, exps)
  
                if (
                  (_fieldToUpdate?.nullable == true) // columnas que admiten nulos
                  || (_fieldToUpdate?.nullable == false && !(newFieldValue == null || newFieldValue == undefined))) // columnas no nulas que llegan valores nulos
                {
                  itemDataRow[newField] = newFieldValue;
                }
                else {
                  MessageService.showMessage(MessageType.Error, 'Error de inserción de datos', 'Valor nulo en campo no nulo.');
                  resolve(false);
                  return false;
                }
  
                return true;
              }
            })
  
          // fin valores por defecto
  
          dataInput = tablasData.filter(x => ((!x.identityColumn && !x.primaryKey) || (!x.identityColumn && x.primaryKey))
            && cds.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.add
          )
            .sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); })
            .map(x => ({
              fieldId: getFieldKeyColumn(x),
              value: itemDataRow[getFieldKeyColumn(x)], 
              required: !x.nullable || cds.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.mandatory
            })) as any
  
          const valuesNoAdd = tablasData.filter(x => ((!x.identityColumn && !x.primaryKey) || (!x.identityColumn && x.primaryKey) || !x.computed)
            && !cds.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.add
          )
            .sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); })
            .map(x => ({
              fieldId: getFieldKeyColumn(x),
              value: itemDataRow[getFieldKeyColumn(x)],  //,getDefaultvalue(x),
              required: !x.nullable || cds.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.mandatory
            })) as any
  
          const dataInputRequest = JSON.parse(JSON.stringify(dataInput.concat(valuesNoAdd)));
  
          const dataUpdate: dataUpdate = {
            valores: dataInputRequest.map((x: any) => ({
              fieldId: getFieldId(x.fieldId,tablasData),
              value: isColumnType(x.fieldId, SqlTypesConst.BIT) ? JSON.stringify(x.value) : x.value
            })) as any,
            claves: massiveOperationType == OperationDataTypeConst.INSERT ? [] : getPrimaryKeys(cds.dataSource, itemDataRow as any, tableName), 
            tipoOperacion: massiveOperationType,
            id: HelperUtils.newGuid()
          };
  
          const datUpOperation:DataUpdateOperation={
            componentId:cds.componentId,
            componentDataSourceId:cds.id,
            dataSourceId:cds.dataSourceId,
            parameters:cds.dataSource.iapDataSourceServiceConfigurations??[],
            data:[dataUpdate]
          }

          dataUpdateOperations.push(datUpOperation);
  
     
        }
      });
      
      if (dataUpdateOperations.length == 0) {
        MessageService.showMessage(MessageType.Error, 'Error de inserción de datos', 'No hay valores que insertar.');
        resolve(false);
      }
      else {        
        HelperOperationData.doOperationData(container,applicationId,applicationVersion, dataUpdateOperations,transactionOperation).then(response=>{
          if(response && response[0]?.result == true){
            
            resolve(true);
            newRegisterLookUp.value=response;
          }
          else{
            resolve(false);
          }
        })
      }

     
    })
  }

  const cleanLookUpFilters = (fieldsIds: string[]) => {
    const cds = getComponentDataSourceFromModelValue()
    let aux: string[] = [];

    fieldsIds.forEach(fieldId => {
      const { tablasData} = DataSourceComp(container as any, {Component:Component}, {}, {}, cds as any, catalogTypes().flatMap(x => x.iapCatalogs), store)

      tablasData.value.forEach(field => {
        if (field.dataSourceLookUpId != null && field.id!=fieldId) {
          const fieldMapsFiltered = field.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.filter(x => x.isFieldFiltered);
          fieldMapsFiltered?.forEach(fm => {
            if (fm.dataSourceFieldIdTarget == fieldId) {
              const _fieldUpdate = getFieldKeyColumn(field)
              var data: Partial<InteractionEvent> = {
                typeId: InteractionConst.SET_VMODEL,
                objectValue: null
              }
              EventBusCustom.emit(Component.formKey + Component.rootParentId.toString() + _fieldUpdate + fieldKeyComponentDataSourceId.value.toString(), { data: data, callBackResponse: null });

              aux.push(field.id);
            }
          })
        }
      })
    });
    if (aux.length > 0) {
      cleanLookUpFilters(aux);
    }
  }

  const setLookUpReference = () => {
    // se va a actualizar el lookupdescription
    const cds = getComponentDataSourceFromModelValue()
    if (fieldKey.value && lookUpData.value && cds) {
      // const originalData = cds.dataSource.items[0] as any
      const campo = fieldKey.value;
      const { tablasData } = DataSourceComp(container as any, {Component:Component}, {}, {}, cds as any, catalogTypes().flatMap(x => x.iapCatalogs), store)
      const dsLk = getFieldFromKeyCol(campo,false,tablasData.value);

      // se actualizan el resto de campos
      Object.keys(lookUpData.value).filter(x => x != DataSourceConst.LOOKUPDESC
        && !x.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS)
      ).forEach(_field => {
        const newValue = lookUpData.value[_field]
        const dslnkFields = dsLk?.dataSourceLookUp?.dataSource.iapDataSourceFields.map(x => ({ id: x.id, field: getFieldKeyColumn(x) }));
        const dataSourceFieldIdSource = dslnkFields?.find((x: any) => x.field == _field)?.id;
        const dataSourceFieldIdTarget = dsLk?.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.find(x => x.dataSourceFieldIdSource == dataSourceFieldIdSource && x.isFieldLinked == false && x.isFieldFiltered == false)?.dataSourceFieldIdTarget;

        const _fieldToUpdate = tablasData.value.find(x => x.id == dataSourceFieldIdTarget)

        if (_fieldToUpdate) {
          const _fieldNew = getFieldKeyColumn(_fieldToUpdate);

          var data: Partial<InteractionEvent> = {
            typeId: InteractionConst.SET_VMODEL,
            objectValue: newValue
          }
          //console.log(rootParentId.toString() + _fieldNew + fieldKeyComponentDataSourceId.value.toString())
          EventBusCustom.emit(Component.formKey + Component.rootParentId.toString() + _fieldNew + fieldKeyComponentDataSourceId.value.toString(), { data: data, callBackResponse: null });
        }
      })

      lookUpData.value = null;
    }
  }

  const initDebounce = () => {
    debounceUpdateData = debounce((data: any, start: boolean = false) => {
      
      if (Component.idType == ControlTypeConst.CTLOOKUPEDITOR) {
        if(!data){
          updateData(data, start)
        }
        else{
          data.forEach((dicElement: any) => {
            updateData(dicElement.value, start,dicElement.key)
          });
        }
      
      }
      else {
        updateData(data, start)
      }
    }, debounceTime);
  }

  const callDependencies = () =>{
    
    if (Component.dependencies?.length > 0){

      Component.dependencies.forEach(x=>{

        var dataToDo: Partial<InteractionEvent> = {
          typeId:x.typeId ?? InteractionConst.CALL_FUNCTION,
          objectName: x.objectName ?? 'resolveattributes()',
          objectId: x.attributeId,
          objectValue: (x.typeId == InteractionConst.SET_VMODEL ? vmodel.value : null)

  
      }

        if (Component.id == x.componentId){
          doOperationEvent({ data: dataToDo as any, callBackResponse: undefined } as any)
        }
        else{
        
    
          const _keyComponentEventBus = Component.formKey +  Component.rootParentId.toString() + x.componentId.toString() + EventConst.INTERACTION;
    
          EventBusCustom.emit(_keyComponentEventBus, { data: dataToDo, callBackResponse: undefined });
        }

      });

      

    }
  }

  const baseOnMounted = () => {
    if (!HelperCommon.isNullOrWhitespace(getProperty(BaseControlTypeConst.MODELVALUE,Component)?.value ?? ''))
    {
      if (canAutoSaveDatasourceComponent) {
        initDebounce()

        watch(
          vmodel, (newvalue, oldvalue) => {          
            if (oldvalue !== newvalue && oldvalue !== undefined) {
              if (isValidData()) {
                setModelValue(newvalue)
                setLookUpReference();
                debounceUpdateData(newvalue, true);
                callDependencies();
              }
            }
          },
          {
            flush: 'post',
            //immediate: true, 
            deep: true
          }
        )
      }
      else {
        watch(
          vmodel, (newvalue, oldvalue) => {
            if (loaded.value && oldvalue !== newvalue) {
              setModelValue(newvalue)
              setLookUpReference();
              callDependencies();
            }
          },
          {
            flush: 'post',
            //immediate: true, 
            deep: true
          }
        )
      }
    }
  }

  const getPropertyValueOptions = (propertyName: string, component = Component) => {
    // forma automatica para los catalogos
    if (dataSourceFieldConfiguration && dataSourceFieldConfiguration?.catalogTypeId) {
      return getCatalogs(dataSourceFieldConfiguration?.catalogTypeId)
    }

    // ver si tengo un origen de datos
    if (component.idType == ControlTypeConst.CTDROPDOWN){


      var catTypeId = component.iapComponentAttributes.find((x) => x.name == DropDownTypeConst.CATALOG_TYPE_ID)?.value ?? '' 
      if (catTypeId){
        return getCatalogs(catTypeId)
      }

      
      var cdsId = component.iapComponentAttributes.find((x) => x.name == DropDownTypeConst.DS)?.value ?? ''
      if (cdsId)
      {
        const cds = componentDataSources.value.find(x=> x.id.toString() == cdsId)
        return cds?.dataSource.items ?? []
      }
      
      return []
    }




    return component.iapComponentAttributes.find((x) => x.name == propertyName)
      ?.value 
      
      
      
  };

  const getPropertyValueObject = (propertyName:string, isArray=false, component=Component) => {
    const resp= component.iapComponentAttributes.find((x) => x.name == propertyName)
      ?.value  ??  (isArray? '[]' :'{}')
    
      if (!HelperCommon.isNullOrWhitespace(resp))
      {
        try { 
          return JSON.parse(resp); 
        } 
        catch (e) 
        { 
          return resp; 
        }
      }
      
  };


const getAttrFileData = async (attr:IapComponentAttribute): Promise<string | null> => {
  return await new Promise<string | null>((resolve) => {
  const fileName = Environment.URL_COMPONENT_ATTR + attr.id + "_" + attr.valueBinaryFileName;
     fetchWrapper.getBinaryFile(fileName + '.zip' + '?_t=' + new Date().getTime().toString(),store)
          .then((response: any) => 
          {                            
              if (response)
              {
                  HelperCompress.decompressFile(attr.id + "_" + attr.valueBinaryFileName, response)
                  .then((responseData: string) => 
                  {                                
                    resolve (responseData);
                  })

              }
          }).catch(() => {
            
              resolve(null)
          })
        })
}

  const getPropertyValueObjectOrFile = (propertyName: string, propertyNameFile: string, propertyNameFieldDataSource: string | null, isArray=false, component=Component) => {    
    const resp = component.iapComponentAttributes.find((x) => x.name == propertyName)

    const respDataSource = component.iapComponentAttributes.find((x) => x.name == propertyNameFieldDataSource)
    if (respDataSource?.value) {
      var dsf = component.iapComponentAttributes.find((x) => x.name == propertyNameFieldDataSource)?.value ?? ''
      if (!HelperCommon.isNullOrWhitespace(dsf) && dsf.includes('#')) {
        const ids = dsf.split('#');
        if (ids.length == 2) {
          const _dataSourceFieldId = ids[0];
          const _componentDataSourceId = parseInt(ids[1]);
          const cds = componentDataSources.value.find(x=> x.id == _componentDataSourceId)
          const _dsf = cds?.dataSource.iapDataSourceFields.find(x => x.id == _dataSourceFieldId)
          if (_dsf) {
            let field = ''
            if (_dsf.dataSourceTableAliasId == null) {
                field = getFieldKeyColumn(_dsf, true);
            }
            else {
                field = getFieldKeyColumn(_dsf);
            }

            if (resp && cds?.dataSource?.items && cds?.dataSource?.items?.length > 0){
              resp.value  = cds?.dataSource.items[0][field];
            }     
          }
        }
      }
    }
    else {
      const respFile= component.iapComponentAttributes.find((x) => x.name == propertyNameFile)
      if (respFile && !HelperCommon.isNullOrWhitespace(respFile?.valueBinaryFileName ?? '')) {
         getAttrFileData(respFile).then( response => {
            if (resp && !HelperCommon.isNullOrWhitespace(response ?? '')) {
              resp.value = response ?? ''
            }
          })
      }
    }
 
    return getPropertyValueObject(propertyName,isArray,component);
      
  };

  // se sobrecargan los metodos base
  const baseOnBeforeUnmount = () => {
    if (canAutoSaveDatasourceComponent) {
      debounceUpdateData.cancel();
    }
  }

  const baseOnUnmount = () => {
    Component.loaded = false
    loaded.value = false;
    vmodel.value = null;

    Component.iapComponentDataSources?.forEach(cds => {
      cds.dataSource.items = null;
      updateCdsDependencies(cds, null)
    })

    if (fieldKeyComponentDataSourceId.value > 0){
      EventBusCustom.off(Component.formKey + Component.rootParentId.toString() + fieldKey.value + fieldKeyComponentDataSourceId.value.toString(),doOperationEvent);
    }
    

    EventBusCustom.off(keyComponentEventBus, doOperationEvent);

    Component.set(Component.id,'loaded', false as any)
  }

  const markUnloadComponents = (tree: ComponentDataForm[]) => {
    
    if (tree) {
      tree?.forEach(x => {        
        if (x.loaded === true){
          x.loaded = false;
          Component.set(x.id,'loaded', false as any)
  
          var data: Partial<InteractionEvent> = {
            typeId: InteractionConst.SET_LOADED,
            objectValue: false
          }
  
          const _keyComponentEventBus = Component.formKey +  Component.rootParentId.toString() + x.id.toString() + EventConst.INTERACTION;
  
          EventBusCustom.emit(_keyComponentEventBus, { data: data, callBackResponse: undefined });
  
        }
        
      })
    }
  }

  const doOperationEvent = async (evt: { data: InteractionEvent, callBackResponse: any }) => {    
    switch (evt.data.typeId) {
      case InteractionConst.GET_PROPERTY_VALUE:
        const attrGet = Component.iapComponentAttributes?.find(x => x.id == evt.data.objectId || x.name == evt.data.objectName)
        if (attrGet) {
          evt.data.interactionResult = attrGet.value;
        }

        break;
      case InteractionConst.SET_PROPERTY_VALUE:
        const attrSet = Component.iapComponentAttributes?.find(x => x.id == evt.data.objectId || x.name == evt.data.objectName)
        if (attrSet) {
          setPropertyValue(attrSet.id, evt.data.objectValue)
          evt.data.interactionResult = attrSet.value;
          // parche para los tabviews y su active index
          if (Component.idType == ControlTypeConst.CTTABVIEW && attrSet.name == TabViewTypeConst.ACTIVEINDEX){
            activeIndex.value = Number(evt.data.objectValue ?? '0')
          }
          //componentKey.value = HelperUtils.newGuid()
        }
        break;
      case InteractionConst.GET_VMODEL:
        evt.data.interactionResult = vmodel.value
        break;
      case InteractionConst.SET_VMODEL:
        if (vmodel.value !== evt.data.objectValue)
        {
          vmodel.value = evt.data.objectValue;
        }
        
        evt.data.interactionResult = vmodel.value
        break;
      case InteractionConst.SET_LOADED:
        loaded.value = evt.data.objectValue;
        evt.data.interactionResult = vmodel.value
        break;
      case InteractionConst.CALL_FUNCTION:

        if (evt.data.objectName?.toLowerCase() == 'isvaliddata()') {
          evt.data.interactionResult = isValidData()
        }
        else if (evt.data.objectName?.toLowerCase() == 'getcurrentexpr()') {
          evt.data.interactionResult = getCurrentExpr();
        }
        else if (evt.data.objectName?.toLowerCase() == 'updatefilters()') {
          updateFilters(evt.data.objectValue);
        }
        else if (evt.data.objectName?.toLowerCase() == 'processevent()') {
          processEvent(CatalogObjectTypeConst.EVTCOMP, evt.data.objectValue)
          evt.data.interactionResult = evt.data.objectValue
        }
        else if (evt.data.objectName?.toLowerCase() == 'resolvedatasource()') {          
          await resolveDatasource(evt.data.objectId).then(() => {
            evt.data.interactionResult = true
          });
        }
        else if (evt.data.objectName?.toLowerCase() == 'resolveattributes()') {    
          await resolveAttributes(evt.data.objectId).then(() => {
            evt.data.interactionResult = true
          });
        }
        

        else if (evt.data.objectName?.toLowerCase() == 'dooperationdatamassive()') {   
                 
          if (evt.data.objectValue){
            const _params = evt.data.objectValue
            //updateData(_params._newValue, _params.start,_params.lkFieldId, _params.modelvalueKeysInput)                      
            await doOperationDataMassive(_params.cds,_params.expressions ?? Component.expressions,_params.operation,_params.trans ?? false).then(() => {
              evt.data.interactionResult = true
            });
  
          }
          evt.data.interactionResult = true

        }
        else if (evt.data.objectName?.toLowerCase() == 'updatedata()') {
          if (evt.data.objectValue){
            const _params = evt.data.objectValue
            updateData(_params._newValue, _params.start,_params.lkFieldId, _params.modelvalueKeysInput)                      
  
          }
          evt.data.interactionResult = true
        }
        break;
    }

    if (evt.callBackResponse) {
      evt.callBackResponse(evt.data);
    }
  }

  const updateFilters = (data: any) => {
    lookUpFilter.value = data;
  }

  const getCurrentExpr = (): IapExpression[] => {
    return Component.expressions;
  }

  // se llama parea que resuelta todas las expresiones que contiene
  if (!loaded.value){


      resolveAllExpressions().then(() => {
        Component.loaded = true;
        Component.set(Component.id,'loaded', true as any)
        vmodel.value = getModelValue();
        
        if (callBackLoaded){
          callBackLoaded()
        }
        //console.log('ORIGINAL:rootParentId.toString() + fieldKey + fieldKeyComponentDataSourceId.value.toString()')
        //console.log(rootParentId.toString() + fieldKey.value + fieldKeyComponentDataSourceId.value.toString())
        if (fieldKeyComponentDataSourceId.value > 0)
        {
          EventBusCustom.on(Component.formKey + Component.rootParentId.toString() + fieldKey.value + fieldKeyComponentDataSourceId.value.toString(), doOperationEvent);
        }
        

        EventBusCustom.on(keyComponentEventBus, doOperationEvent);   
        
        loaded.value = true;     
        
      });
}


  const processEvent = async (idObjeto: string, objetoId: string, componentInput: ComponentDataForm | null = null,currentElementKey:string='', _doCallBackEnd:any=undefined) => {
    let wf: IapWorkFlow | null | undefined = null;
    const comp = componentInput ?? Component

    switch (idObjeto) {
      case CatalogObjectTypeConst.ATTRCOMP:
        wf = comp.workFlows?.find((x: IapWorkFlow) => x.idObjeto == idObjeto && x.objetoId == objetoId)
        break;

      case CatalogObjectTypeConst.EVTCOMP:
        const eventId = comp.iapComponentEvents.find((x: IapComponentEvent) => x.id == objetoId && x.active == true)?.id ?? ''
        wf = comp.workFlows?.find((x: IapWorkFlow) => x.idObjeto == idObjeto && x.objetoId == eventId)

        break;

      default:
        break;
    }

    if (wf && Component.loaded) {

      const keyEventComponentRoot = Component.formKey + Component.rootParentId.toString() + EventConst.INTERACTION;
      var data: Partial<InteractionEvent> = {
        typeId: InteractionConst.GET_ROOT_COMPONENTS,
        objectValue: Component.rootParentId.toString()
      }

      var doCallbackOk = async (response: InteractionEvent) => {
        HelperLoading.showLoading()
        if (response.interactionResult) {
          const comps = response.interactionResult as IapComponent[]
          var doCallBackEnd = () => {
            HelperLoading.hideLoading();
            if (_doCallBackEnd){
              _doCallBackEnd()
            }
          }
          
          updateDictionaryFromRepeaterData()

          await executeWorkFlow(wf as any, comp.applicationId, comp.applicationVersion, Component.rootParentId, Component.formKey,currentElementKey, comp.id, comps, [] as any, container, store, router, doCallBackEnd)
        }
        else {
          MessageService.showMessage(MessageType.Error, 'Error de interacción', 'No hay datos en el nodo raíz.');
        }
        HelperLoading.hideLoading()
      }

      EventBusCustom.emit(keyEventComponentRoot, { data: data, callBackResponse: doCallbackOk });


    }
    else
    {    
      if (_doCallBackEnd){
        _doCallBackEnd()
      }
    }
  }


  const processEventComponent = (idEventType: string, componentInput: ComponentDataForm | null = null,currentElementKey:string='') => {
    const comp = componentInput ?? Component
    const eventId = comp.iapComponentEvents.find((x: IapComponentEvent) => x.idEventType == idEventType && x.active == true)?.id ?? ''
    if (eventId) {
      processEvent(CatalogObjectTypeConst.EVTCOMP, eventId, comp,currentElementKey)
    }
  }

  const isSeparator = (component: ComponentDataForm): boolean => {
    const separatorObject = component.iapComponentAttributes.find((x) => x.name === MenuItemTypeConst.SEPARATOR);
    let separator: boolean = false;

    if (separatorObject !== undefined) {
      separator = HelperUtils.booleanify(separatorObject.value);
    } else {
      separator = false;
    }

    return separator;
  };

  const buildMenu = (menuItem: ComponentDataForm[] = Component.get(Component.id,'children') as ComponentDataForm[]) => {
    var menuItems: Partial<MenuItemClass>[] = [];
    menuItem.filter(x => x.idType == ControlTypeConst.CTMENUITEM && HelperSecurity.canDoOperation(user,Component.objectGroups,ObjectGroupConst.VIEW)).forEach(x => {
      let item: Partial<MenuItemClass>;
      if (isSeparator(x)) {
        item = {
          separator: true,
        }
      }
      else {
        item = {
          key: x.id.toString(),
          label: getPropertyValue(MenuItemTypeConst.LABEL, x),
          visible: getPropertyBooleanValue(MenuItemTypeConst.VISIBLE, x) && (getPropertyValue(MenuItemTypeConst.DS_FIELD, x) == undefined || getPropertyValue(MenuItemTypeConst.DS_FIELD, x) == null),
          icon: getPropertyValue(MenuItemTypeConst.ICON, x),
          class: getPropertyValue(MenuItemTypeConst.CLASS, x),
          items: null,
          field:getPropertyValue(MenuItemTypeConst.DS_FIELD, x) ,
          command: () => {
            processEvent(CatalogObjectTypeConst.ATTRCOMP, getPropertyId(MenuItemTypeConst.COMMAND, x) ?? '', x)
          }
        }

        const _items = buildMenu(x.get(x.id,'children') as ComponentDataForm[]) ?? [];
        if (_items?.length > 0) {
          item.items = _items;
        }
      }

      menuItems.push(item);
    });

    return menuItems as any[];
  };


  

  const sendUbmChat = () =>{
    
    const data :ContactChat ={
      phone : vmodel.value,
      client_name :'',
      client_lastname: '',
      client_email: ''
    }
    HelperLoading.showLoading()
    EventBusCustom.emit(EventConst.SHOWCHATUBM,data);
  }

  const sendMail = () =>{
    window.open('mailto:' + vmodel.value)
  }


const updateDictionaryFromRepeaterData = () =>{
  //para los dataviews podria ir
  const indRepeater = getRepeaterCurrentIndex()
  if (iapComponentAttributesRepeater.length > 0 && indRepeater >=0 )
  {

    iapComponentAttributesRepeater.filter(x=> x.key == indRepeater).flatMap(x=> x.value).forEach(att =>{
      
      updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, att.id].join(ExpressionNomenclatorConst.SEPARATOR), att.value);
    })
  }
}


  return {
    v$,
    submitted,
    canEdit,
    getPropertyValue,
    getPropertyId,
    getPropertyValueOptions,
    setPropertyValue,
    getPropertyBooleanValue,
    getPropertyNumberValue,
    resolveExpressions,
    resolveDatasource,
    vmodel,
    loaded,
    getCatalogValue,
    getProperty,
    updateDictionary,
    dictionaryKeys,
    setPropertyValueByName,
    markUnloadComponents,
    baseOnMounted,
    baseOnBeforeUnmount,
    baseOnUnmount,
    buildMenu,
    getLayoutComponent,
    getContentComponent,
    isLayoutComponent,
    processEvent,
    processEventComponent,
    getAllLayoutComponent,
    getDsLookUpId,
    getDsFieldsLookUp,
    getDsLookUp,
    isValidData,
    doOperationDataMassive,
    lookUpData,
    fieldKey,
    fieldKeyComponentDataSourceId,
    getComponentDataSourceFromModelValue,
    getDsLookUpDesc,
    catalogTypes,
    getPropertyValueOption,
    doOperationEvent,
    getComponentDataSourceItemsFromId,
    getOptionLabelAutocomplete,
    getComponentDataSource,
    lookUpFilter,
    cleanLookUpFilters,
    updateDictionaryFromRow,
    newRegisterLookUp,
    canDoOperation,
    sendUbmChat,
    sendMail,
    hasExpressions,
    getPropertyValueObject,
    fillDataSource,
    getPropertyValueObjectOrFile,
    getModelValueKeys,
    updateData,
    activeIndex,
    isVisibleTabPanel,
    componentKey
  };
}
