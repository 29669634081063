enum Actions {
  // action types
  SENDMAIL = 'sendmail',
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  AUTHMICROSOFT = 'authmicrosoft',
  AUTHGOOGLE = 'authgoogle',
  AUTH = 'auth',
  AUTOAUTH = 'autoauth',
  WHOAMIPROFILES = 'whoAmIProfiles',
  LOGOUT = 'logout',  
  CHANGEPROFILE = 'changeProfile',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  RESET_PASSWORD_MANUAL = 'resetPasswordManual',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  GET_CONFIG = 'config',
  SET_DOBLE_FACTOR_USER_CODE = 'dobleFactorUserCode',
  CONFIRM_DOBLE_FACTOR_USER = 'dobleFactorConfirmUser',
  ENCRYPT = 'ENCRYPT',
  DECRYPT = 'DECRYPT',
  SET_MENU = 'setMenu',
  SET_ROUTES = 'setRoutes',
  GET_APPLAYOUTCOMPONENT = 'getAppLayoutComponent'
}

enum Mutations {
  // mutation types
  SET_CONFIG = 'settings',
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_TOKEN = 'setToken',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_DF_CODE = 'setDFCode',
  WHOAMI = 'whoAmI',
  SET_WHOAMIPROFILES = 'setWhoAmIProfiles',
  SETPROFILE = 'setProfile',
  SETVERSION = 'setVersion',
  SET_PASSWORD = 'setPassword',
  SET_PASSWORD_MANUAL = 'setPasswordManual',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_MENU = 'addMenu',
  SET_ROUTES = 'saveRoutes',
  SET_CLIENTCOMPUTERNAME = 'setComputerName',
  SET_APPCONFIG = 'setAppConfiguration',
  SET_APPLAYOUTCONFIG = 'setAppLayoutConfig',
  SET_ENTITYMAIN_CFG = 'setEntityMainCfg',
  SET_APPLAYOUTCOMPONENT = 'setAppLayoutComponent'
}

export { Actions, Mutations }
