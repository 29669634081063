import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';

import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { GET_ALL_WORKFLOWACTIVITYVARIABLE, GET_WORKFLOWACTIVITYVARIABLE_BY_ID } from './query/workFlowActivityVariableQuery';
import { ADD, UPDATE } from './mutation/workFlowActivityVariableMutation';
import { IWorkFlowActivityVariableRepository } from '../domain/repositorry/IWorkFlowActivityVariableRepository';
import { IapWorkFlowActivityVariable } from '../domain/service/iapWorkFlowActivityVariable';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';





@injectable()
export class WorkFlowActivityVariableApolloClientRepository implements IWorkFlowActivityVariableRepository {
  private apolloClient : MyApolloClient
  private apolloClientId: string
  constructor (apolloInstance: MyApolloClient, _apolloClientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.apolloClientId = _apolloClientId
  }
  

  async update (dataInput: IapWorkFlowActivityVariable[]): (Promise<IapWorkFlowActivityVariable[]>) {
    return new Promise<IapWorkFlowActivityVariable[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityVariableMutation.updateWorkFlowVariable)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IapWorkFlowActivityVariable): (Promise<IapWorkFlowActivityVariable>) {
    return new Promise<IapWorkFlowActivityVariable>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data:dataInput }, this.apolloClientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityVariableMutation.addWorkFlowVariable)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapWorkFlowActivityVariable[]>) {
    return new Promise<IapWorkFlowActivityVariable[]>(async (resolve, reject) => {
      
      const result = this.apolloClient.executeQuery(GET_ALL_WORKFLOWACTIVITYVARIABLE, { maxregs: maxregs }, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityVariableMutation.getAll)
        }
        catch(e) {
          reject()
        }
      });
      
    });

  }

  async getById (id: number): (Promise<IapWorkFlowActivityVariable>) {
    return new Promise<IapWorkFlowActivityVariable>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_WORKFLOWACTIVITYVARIABLE_BY_ID, { id: id}, this.apolloClientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.workFlowActivityVariableMutation.getById)
        }
        catch(e) {
          reject()
        }
      });
      
    });
  }

}


