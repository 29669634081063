import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: "" + process.env.VUE_APP_MICROSOFT_APPID, // ID de la aplicación registrada en Azure AD
    authority: "https://login.microsoftonline.com/" + process.env.VUE_APP_MICROSOFT_TENANTID + "/v2.0", // URL de tu inquilino en Azure
    redirectUri: "http://localhost:8080/signin-microsoft", // Redirigir a la URL de tu aplicación después del inicio de sesión
  },
  cache: {
    cacheLocation: "localStorage", // Puedes usar localStorage para persistir la sesión
    storeAuthStateInCookie: true, // Recomendado para IE11/Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export async function initializeMsalInstance() {
    await msalInstance.initialize();
  }