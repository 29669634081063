import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IDataSourceFieldRepository } from '../domain/IDataSourceFieldRepository';

import { GET_ALL_DATASOURCEFIELD, GET_DATASOURCEFIELD_BY_ID,GET_MAXALL_DATASOURCEFIELD } from './query/dataSourceFieldQuery';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapDataSourceField } from '../domain/iapDataSourceField';
import { UPDATE } from './mutation/dataSourceFieldMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class DataSourceFieldApolloClientRepository implements IDataSourceFieldRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }

  async update (dataInput: IapDataSourceField): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput},this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceFieldMutation.update)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAllByDataSourceId (dataSourceId: number ): (Promise<IapDataSourceField[]>) {
    return new Promise<IapDataSourceField[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_DATASOURCEFIELD, { dataSourceId: dataSourceId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceFieldQuery.getAllByDataSourceId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (dataSourceFieldId: number): (Promise<IapDataSourceField>) {
    return new Promise<IapDataSourceField>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_DATASOURCEFIELD_BY_ID, { dataSourceFieldId: dataSourceFieldId }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceFieldQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapDataSourceField[]>) {
    return new Promise<IapDataSourceField[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_MAXALL_DATASOURCEFIELD, { maxregs: maxregs }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.dataSourceFieldQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


