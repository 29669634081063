
/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { Container } from 'inversify';
import Environment from '../../../../../common/infrastructure/funciones/environment'
import { appConfig } from "../../domain/appConfig";
import Button from 'primevue/button'
import MessageService, { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { helpers, required } from "@vuelidate/validators";
import { resetPass } from "../../domain/resetPass";
import useVuelidate from "@vuelidate/core";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import {
  Actions,
  Mutations,
} from "../../../../../common/infrastructure/almacen/enums/StoreEnums";

const __default__ = {
  components: { Button },
  name: 'forgotpassword',
  props:
    {
        container: {
            type: Object as () => Container
        },
        getLogoUrl: {
            type: String,
            default: ''
        },
        appCfg: {
          type: Object as () => appConfig
        },
    },
  emits: ['isForgetPassChild'],
  setup (props, { emit }) {
    const store = useStore()
    const emailControl = ref < HTMLElement | null > (null)
    const emailConfirmControl = ref < HTMLElement | null > (null)
    const email = ref('')
    const emailconfirm = ref('')
    const ipublicPath = inject('publicPath')
    const submitted = ref(false);
    
    let my_color1 = props.appCfg.color1;
    let my_color2 = props.appCfg.color2;

    /* const user_req: Partial<Usuario> = {
            email: ''
        };

        const request = ref<forgetPasswordRequest>({
            user: user_req as any,
            remoteApplication: 'Portal',
            remoteIp: '',
            requestedUrl: '',
        }); */

    function validateData () {
      
       if (!validateEmail(email.value)) {
          MessageService.showMessage(MessageType.Error, 'Error de validación', 'Debe indicar un e-mail válido.');
          return false;
        }
        if (request.value.email!= request.value.emailconfirm) {
          MessageService.showMessage(MessageType.Error, 'Error de validación', 'Las direcciones de e-mail no coinciden.');
          return false;
        }

      return true
    }

    const back = (event: any) => {
      
      emit('isForgetPassChild', false);
    }

    function validateEmail (email: string) {
      return HelperCommon.validarEmail(email)
    } 

    const onSubmitForgotPassword = () => {
      submitted.value = true;
      if (validateData()) {
        store.dispatch(Actions.FORGOT_PASSWORD, [request.value.email, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
            MessageService.showToast(MessageType.Correcto, '', 'Se ha enviado el correo de reestablecimiento de contraseña correctamente.');
            emit('isForgetPassChild', false);
            //router.push({ name: 'forgotpassword' });
        }).finally(() => {
            HelperLoading.hideLoading();
        });
      }
    }

    const getLogoUrl = computed(() => {
      return Environment.URL_LOGO_PNG;      
    })

    const request = ref<resetPass>({
      email: "",
      emailconfirm: "",
      password: "",
      confirmpass: ""
    });

    const customEmail = (value: string) => {
      return HelperCommon.validarEmail(value);
    };

    const rules = {
      email: {
        required: helpers.withMessage("El email es obligatorio", required),
        customEmail: helpers.withMessage("Correo no válido", customEmail)
      },
      emailconfirm: {
        required: helpers.withMessage("Confirmar el email es obligatorio", required),
        customEmail: helpers.withMessage("Correo no válido", customEmail),
      },
    };

    const v$ = useVuelidate(rules, request);

    return {
      emailControl,
      emailConfirmControl,
      emailconfirm,
      email,
      onSubmitForgotPassword,
      getLogoUrl,
      ipublicPath,
      my_color1,
      my_color2,
      back,
      v$,
      submitted,
      request,
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7c564416-my_color1": (_ctx.my_color1),
  "7c564416-my_color2": (_ctx.my_color2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__