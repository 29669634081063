import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IApplicationNoteRepository } from '../domain/IApplicationNoteRepository';

//import { UPDATE } from './mutation/applicationMutation';
import { GET_ALL_APPLICATIONNOTE, GET_APPLICATIONNOTE_BY_ID,GET_APPLICATIONNOTE_BY_APPLICATIONID } from './query/applicationNoteQuery';
import { IapApplicationNote } from '../domain/iapApplicationNote';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { ADD, DELETE, UPDATE } from './mutation/applicationNoteMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class ApplicationNoteApolloClientRepository implements IApplicationNoteRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
 

  async delete (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationNoteMutation.deleteApplicationNote)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async add (dataInput: IapApplicationNote): (Promise<IapApplicationNote>) {
    return new Promise<IapApplicationNote>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data: dataInput }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationNoteMutation.addApplicationNote)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async update (dataInput: IapApplicationNote): (Promise<IapApplicationNote>) {
    return new Promise<IapApplicationNote>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data: dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationNoteMutation.updateApplicationNote)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapApplicationNote[]>) {
    return new Promise<IapApplicationNote[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_APPLICATIONNOTE, { maxregs: maxregs }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationNoteQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: number): (Promise<IapApplicationNote>) {
    return new Promise<IapApplicationNote>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATIONNOTE_BY_ID, { id: id}, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationNoteQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getByApplicationId (applicationId: number, applicationVersion: number): (Promise<IapApplicationNote[]>) {
    return new Promise<IapApplicationNote[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATIONNOTE_BY_APPLICATIONID, { applicationId: applicationId, applicationVersion: applicationVersion }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationNoteQuery.getByApplicationId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


}


