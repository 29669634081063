<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <span class="p-float-label wrap-input100 validate-input"  :key="componentKey">
        <!--<Dropdown v-if="paths.length > 1" v-model="selectedPathId"  style="width: 250px;" @change="onChangePath()"
                        :options="paths" :showClear="true" optionValue="id" optionLabel="path"
                        placeholder="" @before-show="loadHistory" filter showClear/>-->

        <p>
            <a><img height="16" src="https://intrasoft.plan.io/assets/images/oauth/azureactivedirectory-8038ed1fa53af2b7bbca32c41cb7042c0a7b44fbcbebf4d4eab749f396b5a0ca.png"></a> {{ microsoftName }} <a @click="loginMicrosoft"> {{ microsoftLogin }} </a><br>
            <a><img height="16" src="https://intrasoft.plan.io/assets/images/oauth/google_oauth2-fe78cb53dd314bb9dcd0fd4c4bb7614836acae6c90662d153038b2976e44609e.png"></a>  <a> {{ googleLogin }}</a><br>
            <!--<a class="login-alternative small github" rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/github"><img class="grayscale" height="16" alt="Enable login with GitHub" src="/assets/images/oauth/github-42d3d1aad629581e1da8af36992588cf9ec7ed2fe5d303833ddce81ca7b63edd.png"></a>  <a rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/github">Enable login with GitHub</a><br>
            <a class="login-alternative small linkedin" rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/linkedin"><img class="grayscale" height="16" alt="Enable login with LinkedIn" src="/assets/images/oauth/linkedin-1379fe67af5e752e52672093ab201205192c622fd3d9f0a9adfa256a2c8f7a60.png"></a>  <a rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/linkedin">Enable login with LinkedIn</a><br>
            <a class="login-alternative small bitbucket" rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/bitbucket"><img class="grayscale" height="16" alt="Enable login with Bitbucket" src="/assets/images/oauth/bitbucket-a435a69d492a89ba6856a4810e270fd9f70519a11a7472c87973f4fb89427fa5.png"></a>  <a rel="nofollow" data-method="post" href="https://intrasoft.plan.io/oauth/connect_with/bitbucket">Enable login with Bitbucket</a><br>-->
        </p>
    </span>
</template>
<script lang="ts">

import { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import { shallowRef, defineComponent, onMounted, ref, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import Environment from "@ilinium/shared/src/common/infrastructure/funciones/environment";
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";
import { msalInstance, initializeMsalInstance } from "../../../../../../builderMaster/auth/infrastructure/component/authConfig";
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import { TYPES } from '@ilinium/shared/src/common/domain/types';
import { IServiceUser } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceUser';
import { MessageService } from '@ilinium/shared/src/common/infrastructure/servicios'

export default defineComponent({
    name: 'dynamicsigninwith',
    components: {
        SummaryDebug,
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
        
    },
    setup(props, context) {
        
        const router = useRouter();
        const store = useStore();
        const microsoftName = shallowRef('');
        const googleName = shallowRef('');
        const microsoftLogin = shallowRef('Activar login con Microsoft');
        const googleLogin = shallowRef('Activar login con Google');

        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        let isLoading = ref<boolean>(false);

       const loginMicrosoft = async () => {
            if (microsoftName.value == '') { // Login
                try {
                    // Verificar si hay una interacción pendiente
                    await msalInstance.handleRedirectPromise().catch(error => {
                        console.error("Error en handleRedirectPromise", error);
                    });

                    const loginResponse: AuthenticationResult = await msalInstance.loginPopup({
                        scopes: ["user.read"], // Puedes especificar los permisos que necesitas
                    });

                    // Obtener la información de la cuenta autenticada
                    const account: AccountInfo | null = msalInstance.getAllAccounts()[0];
                    if (account && account.idToken) {
                        //account.username, 
                        await setMicrosoftAccount(account.username);
                        microsoftName.value = account.username ? 'Microsoft (' + account.username + ')' : '';
                        microsoftLogin.value = 'Desactivar'
                    }
                } catch (error) {
                    console.error("Error al iniciar sesión", error);
                }
            }
            else { //logout
                try {
                     // Cierra sesión en todas las cuentas activas
                    const accounts = msalInstance.getAllAccounts();
                    for (const account of accounts) {
                        try {
                            await msalInstance.logoutPopup({ account });
                        } catch (error) {
                            console.error("Error al cerrar sesión en la cuenta", account, error);
                        }
                    }

                    await setMicrosoftAccount(null);
                    microsoftName.value = '';
                    microsoftLogin.value = 'Activar login con Microsoft'
                } catch (error) {
                    console.error("Error al cerrar sesión", error);
                    MessageService.showToast(MessageType.Error, '', 'No se ha podidovincular la cuenta.')
                }
            }
        };

        const setMicrosoftAccount = async (microsoftAccount: string | null) => {
            HelperLoading.showLoading()
            return await new Promise<void>((resolve, reject) => {
                if (props.container) {
                    let user = store.getters.getCurrentUser;

                    const _srvAuth = props.container.get<IServiceUser>(TYPES.USR_REPOSITORY)
                    _srvAuth.updateMicrosoftAccount(user.id, microsoftAccount)
                    .then((response: any) => {
                        HelperLoading.hideLoading()
                        MessageService.showToast(MessageType.Correcto, '', microsoftAccount ? 'Se ha vinculado la cuenta correctamente.' : 'Se ha desvinculado la cuenta correctamente.')
                        resolve(response)
                    })
                    .catch(() => {
                        MessageService.showToast(MessageType.Error, '', 'No se ha podidovincular la cuenta.')
                        HelperLoading.hideLoading()
                        reject()
                    })
                }
            })
        };

        onMounted(async () => {
            await initializeMsalInstance();
            // Verificar si el usuario ya está autenticado al cargar la aplicación
            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                microsoftName.value = 'Microsoft (' + account.username + ')';
                microsoftLogin.value = 'Desactivar'
            }
            else if (store.getters.getCurrentUser.microsoftAccount) {
                microsoftName.value = 'Microsoft (' + store.getters.getCurrentUser.microsoftAccount + ')';
                microsoftLogin.value = 'Desactivar'
            }
        })

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {
            BaseControlTypeConst,
            ObjectGroupConst,
            getPropertyBooleanValue,
            vmodel,
            componentKey,
            loaded,
            microsoftName,
            googleName,
            microsoftLogin,
            googleLogin,
            loginMicrosoft
        };
    },
});
</script>
<style scoped></style>
