import gql from 'graphql-tag';

export const GET_BY_OBJECTID = gql(`
query($idObjeto: String!,$objetoId: String!){
    workFlowQuery{
        getByObjectId(idObjeto:$idObjeto,objetoId:$objetoId){
            id
            applicationId
            applicationVersion
            active
            description
            fcr
            fum
            idObjeto
            group
            name
            objetoId
            objetoIdRoot
            idObjetoRoot            
            ucr
            uum
            description
            iapWorkFlowActivities
            {
            id
            workFlowId
            typeWorkFlowId
            name
            label
            color
            left
            top
            isInvalid
            canDelete
            group
            fcr
            ucr
            uum
            fum
                iapWorkFlowActivityVariables{
                    id
                    workFlowActivityId
                    name
                    isInput
                    defaultValue
                    group
                    fcr
                    ucr
                    uum
                    fum
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                   
                }  
                iapWorkFlowActivityControls{
                        id
                        workFlowActivityId
                        name
                        value
                        localizable
                        group
                    }            

            }
        }
    }
}
`);

export const GET_WORKFLOW_BY_ID = gql(`
query($id: Int) {
  menuQuery {
      getById(id:$id) {
            id
            applicationId
            applicationVersion
            active
            description
            fcr
            fum
            idObjeto
            name
            objetoId
            objetoIdRoot
            idObjetoRoot            
            ucr
            uum
            description
      }
    }
}
`);

