import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ApplicationConfigurationInputType!){
    applicationConfigurationMutation{
        updateApplicationConfiguration(data:$data){
            applicationId,
            applicationVersion,
            idKeyType,
            keyValue,
            keyBinaryValue,
            order,
            fcr,
            ucr,
            uum,
            fum
        }
    }
}
`);

export const ADD = gql(`
mutation($data: ApplicationConfigurationInputType!){
    applicationConfigurationMutation{
        addApplicationConfiguration(data:$data){
            applicationId,
            applicationVersion,
            idKeyType,
            keyValue,
            keyBinaryValue,
            order,
            fcr,
            ucr
        }
    }
}
`);

export const DELETE = gql(`
mutation($applicationId: Int!,$applicationVersion: Int!,$idKeyType: String!){
    applicationConfigurationMutation{
        deleteApplicationConfiguration(id:$id,applicationId:$applicationId,idKeyType:$idKeyType)
    }
}
`);




