import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { IUserRepository } from '../domain/IUserRepository';


import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IapmUser } from '../domain/iapmUser';
import { GET_ALL_USERS_WITH_PROFILES } from './query/userQuery';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { SETGOOGLEACCOUNT, SETMICROSOFTACCOUNT } from './mutation/userMutation';



@injectable()
export class UserApolloClientRepository implements IUserRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
     
  async getAllUsersWithProfiles (): (Promise<IapmUser[]>) {
    return new Promise<IapmUser[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_USERS_WITH_PROFILES, { },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userQuery.getAllUserWithProfiles)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async updateMicrosoftAccount (userId: number, microsoftAccount: string | null): (Promise<Boolean>) {
    return new Promise<Boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(SETMICROSOFTACCOUNT, { userId:userId, microsoftAccount:microsoftAccount },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userMutation.updateMicrosoftAccount)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async updateGoogleAccount (userId: number, googleAccount: string | null): (Promise<Boolean>) {
    return new Promise<Boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(SETGOOGLEACCOUNT, { userId:userId, googleAccount:googleAccount },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.userQuery.updateGoogleAccount)
        }
        catch(e) {
          reject()
        }
      });
    });
  }
}


