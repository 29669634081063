import { IServiceAuth } from './IServiceAuth'
import type { IAuthRepository } from '../domain/IAuthRepository'
import { authRequest } from '../domain/authRequest'
import { authResponse } from '../domain/authResponse'
import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IapmUser } from '../../user/domain/iapmUser'
import { appConfigResponse } from '../domain/appConfigResponse'
import { autoAuthRequest } from '../domain/autoAuthRequest'
import { UsuarioDobleAutenticacion } from '../../user/domain/usuarioDobleAutenticacion'
import { dobleAuthenticationRequest } from '../domain/dobleAuthenticationRequest'



@injectable()
export class AuthService implements IServiceAuth {
  private readonly authRepo: IAuthRepository

  public constructor (@inject(TYPES.AUTH_REPOSITORY) _authRepo: IAuthRepository) {
    this.authRepo = _authRepo
  }

  async authMicrosoft (request: authRequest): (Promise<authResponse>) {
    return await this.authRepo.authMicrosoft(request)
  }

  async authGoogle (request: authRequest): (Promise<authResponse>) {
    return await this.authRepo.authGoogle(request)
  }

  async auth (request: authRequest): (Promise<authResponse>) {
    return await this.authRepo.auth(request)
  }

  async autoAuth (request: autoAuthRequest): (Promise<authResponse>) {
    return await this.authRepo.autoAuth(request)
  }

  async logout (): (Promise<boolean>) {
    return await this.authRepo.logout()
  }

  async getVersion (): (Promise<string>) {
    return await this.authRepo.getVersion()
  }

  async whoAmI (): (Promise<IapmUser>) {
    return await this.authRepo.whoAmI()
  }

  async resetPass (request: authRequest): (Promise<IapmUser>) {
    return await this.authRepo.resetPass(request)
  }

  async setPassword (request: authRequest): (Promise<boolean>) {
    return await this.authRepo.setPassword(request)
  }

  async setPasswordManual (request: authRequest): (Promise<boolean>) {
    return await this.authRepo.setPasswordManual(request)
  }
  async whoAmIProfiles (): (Promise<IapmUser>) {
    return await this.authRepo.whoAmIProfiles()
  }

  async getAppConfig (applicationId: number, applicationVersion: number): (Promise<appConfigResponse>) {
    return await this.authRepo.getAppConfig(applicationId, applicationVersion)
  }

  async decryptApiAuth (request: authRequest): (Promise<string>) {
    return await this.authRepo.decryptApiAuth(request)
  }

  // Métodos doble autenticación
  async getDobleFactorUserCode (request: authRequest): (Promise<UsuarioDobleAutenticacion>) {
    return await this.authRepo.getDobleFactorUserCode(request)
  }
  async setDobleFactorUserCode (request: dobleAuthenticationRequest): (Promise<UsuarioDobleAutenticacion>) {
    return await this.authRepo.setDobleFactorUserCode(request)
  }
  async confirmDobleFactorUser (request: dobleAuthenticationRequest): (Promise<UsuarioDobleAutenticacion>) {
    return await this.authRepo.confirmDobleFactorUser(request)
  }
}
