import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';

import { IApplicationRepository } from '../domain/IApplicationRepository';

//import { UPDATE } from './mutation/applicationMutation';
import { GET_ALL_APPLICATIONS, GET_APPLICATION_BY_ID, GET_APPLICATION_CATALOGS, GET_APPLICATION_CATALOG_TYPES, GET_APPLICATION_CATALOG_TYPES_BY_TYPES, GET_INITIAL_DATA } from './query/applicationQuery';
import { IapApplication } from '../domain/iapApplication';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { ApplicationData } from '../domain/applicationData';
import { ADD, DELETE, UPDATE } from './mutation/applicationMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { IapCatalogType } from '../../catalog/domain/iapCatalogType';



@injectable()
export class ApplicationApolloClientRepository implements IApplicationRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  
  

  async getInitialData (): (Promise<ApplicationData>) {
    return new Promise<ApplicationData>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_INITIAL_DATA,null, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationQuery.getApplicationData)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async delete (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     const result = this.apolloClient.executeQuery(DELETE, { id: id }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationMutation.deleteComponent)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async add (dataInput: IapApplication): (Promise<IapApplication>) {
    return new Promise<IapApplication>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD, { data: dataInput }, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationMutation.addApplication)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async update (dataInput: IapApplication): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data: dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationMutation.updateApplication)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async getAll (maxregs: number | undefined): (Promise<IapApplication[]>) {
    return new Promise<IapApplication[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_ALL_APPLICATIONS, { maxregs: maxregs }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationQuery.getAll)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getById (id: number, version: number): (Promise<IapApplication>) {
    return new Promise<IapApplication>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATION_BY_ID, { id: id, version: version }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationQuery.getById)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async getApplicationCatalogs (id: number, version: number) :Promise<IapCatalogType[]> {
    return new Promise<IapCatalogType[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATION_CATALOGS, { id: id, version: version }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationQuery.getApplicationCatalogs)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


  async getApplicationCatalogTypes (id: number, version: number) :Promise<IapCatalogType[]> {
    return new Promise<IapCatalogType[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATION_CATALOG_TYPES, { id: id, version: version }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationQuery.getApplicationCatalogTypes)
        }
        catch(e) {
          reject()
        }
      });
    });
  }

  async getApplicationCatalogTypesByTypeIds (id: number, version: number,catalogTypeIdList:string[]) :Promise<IapCatalogType[]> {
    return new Promise<IapCatalogType[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GET_APPLICATION_CATALOG_TYPES_BY_TYPES, { id: id, version: version, typeIds:catalogTypeIdList }, this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.applicationQuery.getApplicationCatalogTypesByTypeIds)
        }
        catch(e) {
          reject()
        }
      });
    });
  }


 
  


}


